import React from "react";
import { Chart } from "chart.js";
import * as Chartjs from "chart.js";
import { Bar, Line } from "react-chartjs-2";
// import {
//   CategoryScale,
//   Chart as ChartJS,
//   LineElement,
//   LinearScale,
//   PointElement,
//   Tooltip,
// } from "chart.js";
import dayjs from "dayjs";
import { getRandomBackgroundColor } from "../../utils/helpers";

const controllers = Object.values(Chartjs).filter(
  (chart) => chart.id !== undefined
);

const skipped = (ctx, value) =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

Chart.register(...controllers);

// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const CurveChart = ({
  allDates,
  selectedBodyParts,
  fromPdf = false,
  chartRef,
}) => {
  const labels = fromPdf
    ? allDates?.map((data) => dayjs(data?.date).format("MM/DD"))
    : allDates?.map((data) => dayjs(data?.date).format("MM/DD"));

  const individualBodyPartPain = allDates?.map((item) => ({
    bodyPart: item.body_parts_data,
    date: item.date,
  }));

  const dates_from_pain_entries = allDates?.map((data) =>
    dayjs(data?.date).format("YYYY-MM-DD")
  );

  const no_medicine = allDates?.map((date) => {
    const sleepLossEntry = date?.body_parts_data?.find(
      (entry) => entry?.medicine_adherence === "no"
    );
    return sleepLossEntry ? 10 : null;
  });

  const not_prescribed_medicine = allDates?.map((date) => {
    const sleepLossEntry = date?.body_parts_data?.find(
      (entry) => entry?.medicine_adherence === "na"
    );
    return sleepLossEntry ? 10 : null;
  });

  const data = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Medication missed",
        backgroundColor: "rgba(255, 32, 32, 0.4)",
        barThickness: 4,
        data: no_medicine,
      },
      {
        type: "bar",
        label: "Medication not prescribed",
        backgroundColor: "#FEEBB1",
        barThickness: 4,
        data: not_prescribed_medicine,
      },
      ...selectedBodyParts?.map((bodyPart, index) => {
        const backgroundColor = getRandomBackgroundColor(index);
        const pain_values = [];
        for (const pain_entry_date of dates_from_pain_entries) {
          let found = false;
          for (const individual_pain_entry of individualBodyPartPain) {
            for (const individual_body_part of individual_pain_entry?.bodyPart ||
              []) {
              if (
                `${individual_body_part?.side?.toLowerCase()} ${individual_body_part?.name?.toLowerCase()}` ===
                  bodyPart?.toLowerCase() &&
                individual_pain_entry?.date === pain_entry_date
              ) {
                pain_values.push(individual_body_part?.pain_scale);
                found = true;
              }
            }
          }

          if (!found) {
            pain_values.push(null);
          }
        }

        return {
          data: pain_values,
          backgroundColor,
          borderColor: backgroundColor,
          label: bodyPart.charAt(0).toUpperCase() + bodyPart.slice(1),
          spanGaps: true,
          pointStyle: "circle",
          pointRadius: 2,
          borderWidth: 1,
          segment: {
            borderColor: (ctx) => skipped(ctx, getRandomBackgroundColor(index)),
            borderDash: (ctx) => skipped(ctx, [6, 6]),
          },
        };
      }),
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Pain Level",
        },
        beginAtZero: true,
        max: 11,
        ticks: {
          callback: (value, index, values) => {
            return value === 11 ? null : value;
          },
          stepSize: 2,
        },
      },
    },
    plugins: {
      customCanvasBackgroundColor: {
        color: "#fff",
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: "rectRounded",
          borderRadius: 11,
          color: "rgba(0, 0, 0, 0.38)",
          font: {
            size: 12,
          },
        },
        align: "end",
      },
    },
  };

  // const options = {
  //   plugins: {
  //     legend: false,
  //     // tooltip: {
  //     //   callbacks: {
  //     //     title: (xDatapoint) => {
  //     //       console.log(xDatapoint);
  //     //       // return formatXValue(xDatapoint.raw);
  //     //     },
  //     //     label: (yDatapoint) => {
  //     //       console.log(yDatapoint);
  //     //       // return formatYValue(yDatapoint.raw);
  //     //     },
  //     //   },
  //     // },
  //   },
  //   showTooltips: true,
  //   // tooltips: {
  //   //   mode: "index",
  //   //   intersect: false,
  //   // },
  //   hover: {
  //     mode: "index",
  //     intersect: false,
  //   },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   hoverRadius: 30,
  //   // scales: {
  //   //   x: {
  //   //     grid: { display: false },
  //   //   },
  //   //   y: {
  //   //     min: 0,
  //   //     max: 5,
  //   //     ticks: {
  //   //       stepSize: 1,
  //   //     },
  //   //     grid: {
  //   //       borderDash: 10,
  //   //     },
  //   //   },
  //   // },
  //   // tooltips: {
  //   //   callbacks: {
  //   //     label: function (tooltipItem, data) {
  //   //       console.log("TOOLTIPITEM", tooltipItem);
  //   //       console.log("DATA", data);
  //   //       const label = data.datasets[tooltipItem.datasetIndex].label || "";
  //   //       const value = tooltipItem.yLabel;
  //   //       return `${label}: ${value}`;
  //   //     },
  //   //   },
  //   // },
  // };

  return (
    <Line
      ref={chartRef}
      data={data}
      options={options}
      style={{ width: "100% !important" }}
    />
  );
};

export default CurveChart;
