import React from "react";

const HomeIcon = ({ color = "#212121" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6.765 2.13061L2.7225 5.28061C2.0475 5.80561 1.5 6.92311 1.5 7.77061V13.3281C1.5 15.0681 2.9175 16.4931 4.6575 16.4931H13.3425C15.0825 16.4931 16.5 15.0681 16.5 13.3356V7.87561C16.5 6.96811 15.8925 5.80561 15.15 5.28811L10.515 2.04061C9.465 1.30561 7.7775 1.34311 6.765 2.13061Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.4922V11.2422"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
