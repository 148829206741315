import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  DownloadedReport,
  DownloadingIcon,
  ReportErrorWarning,
} from "../../assets/constants";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const DownloadReportStatus = ({
  monthName,
  downloadReportDone,
  setDownloadReportDone,
  setExportReportError,
  exportReportError,
  progressNum,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="15px"
      bgcolor={exportReportError ? "#FFF4E5" : "#F4F6F8"}
      padding="16px"
      borderRadius="8px"
      width="468px"
      position="absolute"
      bottom="20px"
      right="40px"
      zIndex="9999"
    >
      <Box
        borderRadius="7px"
        padding="10px"
        bgcolor={exportReportError ? "#FFEBD1" : "#FFF"}
      >
        {exportReportError ? (
          <ReportErrorWarning />
        ) : downloadReportDone ? (
          <DownloadedReport />
        ) : (
          <DownloadingIcon />
        )}
      </Box>
      <Stack gap="5px" width="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="6px"
        >
          <Typography color="#000" variant="subtitle1" fontSize="15px">
            Evidence of care{" "}
            <Typography
              component="span"
              color="rgba(0, 0, 0, 0.38)"
              variant="body1"
            >
              {monthName}
            </Typography>
          </Typography>
          {downloadReportDone || exportReportError ? (
            <IconButton
              onClick={() => {
                setDownloadReportDone(false);
                setExportReportError(false);
              }}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>
          ) : (
            <Typography color="#0D0D0D" variant="body1">
              Downloading
            </Typography>
          )}
        </Box>
        {!downloadReportDone && !exportReportError && (
          <Box
            sx={{
              width: "100%",
              height: "8px",
              bgcolor: "#E0E0E0",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: `${progressNum}%`,
                height: "100%",
                bgcolor: progressNum >= 100 ? "#4CAF50" : "#1976D2",
                transition: "width 0.5s ease",
              }}
            />
          </Box>
        )}
        {exportReportError && (
          <Typography color="#663C00" variant="body1">
            Something went wrong.{" "}
            <Box
              component="span"
              color="#0C80F3"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {" "}
              Retry
            </Box>{" "}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default DownloadReportStatus;
