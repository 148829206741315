import React, { useEffect, useRef, useState } from "react";
import CardUI from "../../../../common/Card";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAgencyDetailFunc,
  updateAgencyFunc,
} from "../../../../actions/Agency";
import Cookies from "js-cookie";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";
import AlertMessage from "../../../../common/AlertMessage";

const Patients = () => {
  const [painDays, setPainDays] = useState("");
  const [error, setError] = useState("");
  const debounceTimeout = useRef(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  const updateAgency = useSelector((state) => state.updateAgency);
  const {
    loading: loadingAgency,
    error: errorAgency,
    updatedAgency,
  } = updateAgency;

  const dispatch = useDispatch();
  const cookieAgencyId = Cookies.get("agency_id_vita");

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => {
    if (!agencyDetail) {
      dispatch(getAgencyDetailFunc());
    }

    setPainDays(agencyDetail?.agency?.number_of_days_for_77);
  }, [agencyDetail]);

  // console.log("PPP", painDays);
  // console.log("AAA", agencyDetail);

  const painDaysHanlder = (e) => {
    const value = e.target.value;

    if (value === "" || value === 0) {
      setPainDays("");
      setError("");
      return;
    }

    if (Number(value) >= 1 && Number(value) <= 31) {
      setPainDays(value);
      setError("");

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const agencyData = {
          agency_id: cookieAgencyId,
          agency_settings: {
            number_of_days_for_77: Number(value),
          },
        };
        dispatch(updateAgencyFunc(agencyData));
        setAlertOpen(true);
      }, 1000);
    } else {
      setAlertOpen(false);
      setError("Please put a number from 1 to 31.");
    }
  };

  return (
    <>
      {!loadingAgency && updatedAgency?.ok && (
        <AlertMessage
          message="Your changes have been saved successfully"
          severity="success"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      <CardUI
        maxWidth="741px"
        padding="24px !important"
        boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
      >
        <Stack gap="16px">
          <Stack>
            <Typography
              variant="boxHeading"
              color="secondary"
              sx={{ textTransform: "uppercase" }}
            >
              CPT Code 98977 Settings
            </Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
              Configure requirements for CPT Code 98977{" "}
            </Typography>
          </Stack>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            gap="8px"
            width="100%"
          >
            <Typography
              component="label"
              htmlFor="pain-days"
              variant="body1"
              fontWeight={400}
            >
              Number of Days with Pain Records{" "}
            </Typography>
            {loadingAgencyDetail ? (
              <Box>
                <RectangleSkeletons height={40} totalRows={1} width="143px" />
              </Box>
            ) : (
              <TextField
                type="number"
                size="small"
                error={Boolean(error)}
                helperText={error}
                id="pain-days"
                value={painDays}
                onChange={painDaysHanlder}
                sx={{
                  width: "145px",
                  "& input[type=number]": {
                    "-mozAppearance": "textfield", // Firefox
                    "&::-webkit-outer-spin-button": {
                      "-webkitAppearance": "none", // Chrome, Safari, Edge
                      margin: 0,
                    },
                    "&::-webkit-inner-spin-button": {
                      "-webkitAppearance": "none", // Chrome, Safari, Edge
                      margin: 0,
                    },
                  },
                }}
              />
            )}
          </Box>
        </Stack>
      </CardUI>
    </>
  );
};

export default Patients;
