import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";

const NetworkStatusBanner = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Initial state based on current connection

  useEffect(() => {
    // Event listeners for online and offline events
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup the event listeners
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <Alert
          severity="error"
          style={{
            position: "fixed",
            zIndex: 100,
            textAlign: "center",
            left: "50%",
            transform: "translate(-25%, 0)",
          }}
        >
          You are offline. Please check your internet connection.
        </Alert>
      )}
    </>
  );
};

export default NetworkStatusBanner;
