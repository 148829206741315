import React from "react";

const FailedChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 2.33398C11.406 2.33398 14.1673 5.09465 14.1673 8.50065C14.1673 11.906 11.406 14.6673 8.00065 14.6673C4.59465 14.6673 1.83398 11.906 1.83398 8.50065C1.83398 5.09465 4.59465 2.33398 8.00065 2.33398Z"
        stroke="#E76072"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9974 5.96875V8.91475"
        stroke="#E76072"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M7.99602 11.0306H8.00268"
        stroke="#E76072"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FailedChatIcon;
