import React, { useMemo } from "react";
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// const cellWidths = [87, 144, 150, 100, 50, 150, 150];

const CaseTableSkeleton = ({
  headers,
  numRows,
  cellWidths = [87, 144, 150, 100, 50, 150, 150],
  marginTop = "24px",
  height = 550,
  headerInclude = true,
}) => {
  return (
    <TableContainer sx={{ marginTop: marginTop, height: height }}>
      <Table>
        {headerInclude && (
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  sx={{
                    padding: "12px 10px !important",
                    textAlign: index === 5 ? "right !important" : "left",
                  }}
                  key={index}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {Array(numRows)
            .fill()
            .map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((_, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{ minWidth: cellWidths[cellIndex] }}
                  >
                    <Skeleton variant="text" height={20} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CaseTableSkeleton;
