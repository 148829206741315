import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import CalendarIcon from "../../icons/CalendarIcon";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setCommunicationInteraction,
  setDateValue,
  setEndTime,
  setLogTypeValue,
  setNotesValue,
  setStartTime,
  timeChangedFunc,
} from "../../actions/AppConfig";
import { logValueOptions } from "../../utils/helpers";
import dayjs from "dayjs";

const EndTimeModalContent = ({ endTimeError, setEndTimeError, openModal }) => {
  const dispatch = useDispatch();
  const timerModal = useSelector((state) => state.timerModal);
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);

  const {
    startTimeValue,
    endTimeValue,
    logTypeValue,
    notesValue,
    goBackClicked,
    isPaused,
    isRunning,
    isStopped,
    logoutClicked,
    navigateItem,
    communicationInteraction,
    timeChanged,
  } = timerModal;

  const updateEndTimeIfNeeded = (dispatch, endTimeValue) => {
    const now = dayjs().set("second", 0).set("millisecond", 0);
    const endTime = dayjs(endTimeValue).set("second", 0).set("millisecond", 0);

    // Update only if the current minute has changed compared to the end time minute
    if (now.minute() !== endTime.minute()) {
      dispatch(setEndTime(now));
    }
  };

  useEffect(() => {
    let animationFrameId;

    const checkTimeUpdate = () => {
      // Request the next animation frame
      animationFrameId = requestAnimationFrame(checkTimeUpdate);

      // Call the helper function to check and update end time
      updateEndTimeIfNeeded(dispatch, endTimeValue);
    };

    if (openModal && !timeChanged) {
      animationFrameId = requestAnimationFrame(checkTimeUpdate);
    }

    // Cleanup function to cancel the animation frame
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [openModal, timeChanged, endTimeValue, dispatch]);

  return (
    <Stack gap="21px">
      {(goBackClicked || logoutClicked || navigateItem) && !isStopped && (
        <Alert severity="warning">
          The timer is running. Please save your time log before leaving the
          page, or it will be discarded.
        </Alert>
      )}

      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap="24px"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Start Time"
            value={startTimeValue}
            onChange={(newValue) => {
              dispatch(setStartTime(newValue));
              dispatch(timeChangedFunc(true));
            }}
            onOpen={() => setOpenStartTimePicker(true)}
            onClose={() => setOpenStartTimePicker(false)}
            open={openStartTimePicker}
            slotProps={{
              textField: {
                fullWidth: true,
                onClick: () => setOpenStartTimePicker(true),
              },
            }}
            timeSteps={{ minutes: 1 }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="End Time"
            value={endTimeValue}
            onChange={(newValue) => {
              setEndTimeError(false);
              dispatch(setEndTime(newValue));
              dispatch(timeChangedFunc(true));
            }}
            onOpen={() => setOpenEndTimePicker(true)}
            onClose={() => setOpenEndTimePicker(false)}
            open={openEndTimePicker}
            slotProps={{
              textField: {
                fullWidth: true,
                error: endTimeError,
                helperText: endTimeError
                  ? "End time cannot be earlier than start time."
                  : "",
                sx: endTimeError
                  ? {
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D32F2F",
                        },
                      },
                      "& .MuiFormLabel-root.Mui-error": {
                        color: "#D32F2F",
                      },
                      "& .MuiFormHelperText-root.Mui-error": {
                        color: "#D32F2F",
                      },
                    }
                  : {},

                // color: "#D32F2F",
                onClick: () => setOpenEndTimePicker(true),
              },
            }}
            timeSteps={{ minutes: 1 }}
          />
        </LocalizationProvider>
      </Box>

      <CustomSelectFilter
        menuItems={logValueOptions}
        changeSelectValueHandler={(e) =>
          dispatch(setLogTypeValue(e.target.value))
        }
        selectValue={logTypeValue}
        id="log_type"
        label="Log Type"
        minWidth="fullWidth"
        size="medium"
      />

      <FormControlLabel
        sx={{ marginRight: "0", flex: "1" }}
        control={
          <Checkbox
            checked={communicationInteraction}
            onChange={(e) =>
              dispatch(setCommunicationInteraction(e.target.checked))
            }
          />
        }
        label="Interacted with the patient in this session."
      />

      <TextField
        multiline
        rows={6}
        label="Notes"
        value={notesValue}
        onChange={(e) => dispatch(setNotesValue(e.target.value))}
      />
    </Stack>
  );
};

export default EndTimeModalContent;
