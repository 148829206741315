import pdfImg from "../../assets/images/pdfImg.png";
import Img from "../../assets/images/image-icon.png";
import docImg from "../../assets/images/docImg.png";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import styles from "../../components/NotificationDrawer/Notifications.module.css";
import VitatraxIcon from "../../icons/VitatraxIcon";
import HospitalIcon from "../../icons/HospitalIcon";
import BuildingIcon from "../../icons/BuildingIcon";
import FlashIcon from "../../icons/FlashIcon";
import HomeIcon from "../../icons/HomeIcon";
import StarIcon from "../../icons/StarIcon";
import BriefCaseIcon from "../../icons/BriefCaseIcon";
import { ActiveIcon, InactiveIcon, PendingIcon } from "../../assets/constants";
dayjs.extend(relativeTime);

export function getMyValue(value) {
  if (value === null || value === undefined || value === "null") {
    return;
  }
  return value;
}

export const formatLastReadingTime = (dateString) => {
  const date = dayjs(dateString).startOf("day");
  const today = dayjs().startOf("day");
  const daysDiff = today.diff(date, "day");

  if (daysDiff === 0) {
    return "Today";
  } else if (daysDiff === 1) {
    return "Yesterday";
  } else if (daysDiff <= 7) {
    return `${daysDiff} days ago`;
  } else {
    return date.format("DD MMMM");
  }
};

export const logValueOptions = [
  { title: "Therapeutic Data Review", value: "Therapeutic Data Review" },
  { title: "Communication with Patient", value: "Communication with Patient" },
  { title: "Chart Review", value: "Chart Review" },
  { title: "Care Coordination", value: "Care Coordination" },
];

export const formatElapsedTime = (elapsed) => {
  const hours = Math.floor(elapsed / 3600000)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((elapsed % 3600000) / 60000)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((elapsed % 60000) / 1000)
    .toString()
    .padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const formatElapsedTimeForListing = (elapsed) => {
  const hours = Math.floor(elapsed / 3600000);
  const minutes = Math.floor((elapsed % 3600000) / 60000);
  return hours ? `${hours} hrs ${minutes} min` : `${minutes} min`;
};

export const calculateElapsedTime = (startTime, endTime) => {
  const start = dayjs(startTime)?.set("second", 0).set("millisecond", 0);
  const end = dayjs(endTime)?.set("second", 0).set("millisecond", 0);
  return end.diff(start, "second");
};

// export const formatElapsedTime = (elapsed) => {
//   const hours = Math.floor(elapsed / 3600)
//     .toString()
//     .padStart(2, "0");
//   const minutes = Math.floor((elapsed % 3600) / 60)
//     .toString()
//     .padStart(2, "0");
//   const seconds = Math.floor(elapsed % 60)
//     .toString()
//     .padStart(2, "0");
//   return `${hours}:${minutes}:${seconds}`;
// };

export function stringAvatar(name) {
  const newName = name
    ? name?.replace(/[^a-zA-Z0-9]/g, " ")?.replace(/\s\s+/g, " ")
    : "VitaTrax";
  return {
    children: `${newName?.split(" ")[0][0]}${
      newName?.split(" ")[1] ? newName?.split(" ")[1][0] : ""
    }`,
  };
}

export function getIconColor(iconColor) {
  let bgColor;
  switch (iconColor) {
    case "green":
      bgColor = "linear-gradient(136deg, #00D55A 7.5%, #007257 93.77%)";
      break;
    case "blue":
      bgColor = "linear-gradient(136deg, #4CA4EC 7.5%, #2C5FFE 93.77%)";
      break;
    case "red":
      bgColor = "linear-gradient(136deg, #D33D3E 7.5%, #812526 93.77%)";
      break;
    case "violet":
      bgColor = "linear-gradient(136deg, #9249F1 7.5%, #63338E 93.77%)";
      break;

    default:
      bgColor = "linear-gradient(136deg, #4CA4EC 7.5%, #2C5FFE 93.77%)";
      break;
  }

  return bgColor;
}

// { iconName: <BriefCaseIcon />, value: "briefcase_icon", icon: true },

export function getIconName(iconName) {
  let icon;
  switch (iconName) {
    case "vitatrax_icon":
      icon = <VitatraxIcon color="#fff" />;
      break;
    case "hospital_icon":
      icon = <HospitalIcon color="#fff" />;
      break;
    case "building_icon":
      icon = <BuildingIcon color="#fff" />;
      break;
    case "flash_icon":
      icon = <FlashIcon color="#fff" />;
      break;
    case "home_icon":
      icon = <HomeIcon color="#fff" />;
      break;
    case "star_icon":
      icon = <StarIcon color="#fff" />;
      break;
    case "briefcase_icon":
      icon = <BriefCaseIcon color="#fff" />;
      break;

    default:
      icon = <VitatraxIcon color="#fff" />;
      break;
  }

  return icon;
}

export const getLogTypeValues = (logType) => {
  let logTypeValue;
  switch (logType) {
    case "chat_communication":
      logTypeValue = "Chat & Communication";
      break;
    case "All":
      logTypeValue = "All";
      break;
    default:
      logTypeValue = "All";
      break;
  }

  return logTypeValue;
};

// Deductible Issues
// Change of Insurance
// No longer in Pain
// Deceased
// Not Active / No Engagement
// Not Interested
// Other

export const unEnrollmentReasonsOptions = [
  { title: "Deductible Issues", value: "Deductible Issues" },
  { title: "Change of Insurance", value: "Change of Insurance" },
  { title: "No longer in Pain", value: "No longer in Pain" },
  { title: "Deceased", value: "Deceased" },
  { title: "Not Active / No Engagement", value: "Not Active / No Engagement" },
  { title: "Not Interested", value: "Not Interested" },
  { title: "Other", value: "Other" },
];

export function getRandomBackgroundColor(index) {
  let bgColor;
  switch (index) {
    case 0:
      bgColor = "#0C80F3";
      break;
    case 1:
      bgColor = "rgb(255, 99, 132)";
      break;
    case 2:
      bgColor = "rgb(255, 205, 86)";
      break;
    case 3:
      bgColor = "rgb(75, 192, 192)";
      break;
    case 4:
      bgColor = "#0C80F3";
      break;
    case 5:
      bgColor = "rgb(255, 99, 132)";
      break;
    case 6:
      bgColor = "rgb(255, 205, 86)";
      break;
    case 7:
      bgColor = "rgb(75, 192, 192)";
      break;
    case 8:
      bgColor = "#0C80F3";
      break;
    case 9:
      bgColor = "rgb(255, 99, 132)";
      break;
    case 10:
      bgColor = "rgb(255, 205, 86)";
      break;
    case 11:
      bgColor = "rgb(75, 192, 192)";
      break;
    default:
      bgColor = "rgb(153, 102, 255)";
      break;
  }

  return bgColor;
}

export function getStepCountDaysText(days) {
  let formatText;
  switch (days) {
    case 14:
      formatText = "Last 14 Days";
      break;

    case 31:
      formatText = "Last 1 Month";
      break;
    case 92:
      formatText = "Last 3 Months";
      break;
    case 365:
      formatText = "Last 12 Months";
      break;

    default:
      formatText = "Custom";
      break;
  }

  return formatText;
}

export function removeLastExtension(fileName) {
  const lastDotIndex = fileName?.lastIndexOf(".");

  if (lastDotIndex !== -1) {
    const nameWithoutExtension = fileName?.substring(0, lastDotIndex);
    const extension = fileName?.substring(lastDotIndex);

    return { nameWithoutExtension, extension };
  } else {
    // No extension found
    return fileName;
  }
}

export const bodyPainWord = (bodyPartName) => {
  const formattedBodyPartName = bodyPartName.replace("-", " ");
  return formattedBodyPartName;
};

export const exportPdfPainData = (allData) => {
  const painTableData = [];
  let srNo = 1;

  // Loop over allData
  allData?.forEach((data, index) => {
    const createdTime = data?.created_at
      ? dayjs(data?.created_at).format("hh:mm A")
      : "-";
    // Overall Body Part
    // if (data?.global_pain_level !== null) {
    //   return;
    // const row = [
    //   srNo++,
    //   data?.date,
    //   createdTime,
    //   "Overall",
    //   data?.global_pain_level,
    //   "-",
    // ];
    // painTableData.push(row);
    // } else {
    //   const row = [srNo++, data?.date, createdTime, "", "-", "-"];
    //   painTableData?.push(row);
    // }

    const parsedData = JSON.parse(data?.body_parts_data);

    // Loop over body_parts_data
    parsedData?.forEach((bodyPart, bodyPartIndex) => {
      const row = [
        srNo++,
        data?.date,
        createdTime,
        `${bodyPart?.side}-${bodyPart?.name}`,
        bodyPart?.pain_scale,
        bodyPart?.description ? bodyPart?.description : "-",
      ];
      painTableData.push(row);
    });
  });

  return painTableData;
};

export const checkFileExtension = (fileName) => {
  const fileExtension = fileName?.split(".")?.pop();

  const imageExtensions = ["jpg", "jpeg", "png"];

  const isImage = imageExtensions?.includes(fileExtension?.toLowerCase());
  return isImage;
};

export const onlyImageName = (imageName) => {
  const parts = imageName?.split(".");

  const nameWithoutExtension = parts?.slice(0, -1)?.join(".");

  return nameWithoutExtension;
};

export const getCaseValueText = (caseValue) => {
  const option = caseTypeOptions.find((option) => option.value === caseValue);
  return option ? option.title : caseValue;
};

export const isPDF = (fileName) => {
  return fileName.toLowerCase().endsWith(".pdf");
};

// export const exportCsvData = (data) => {
//   // Create CSV data
//   const csvData = [];
//   csvData.push([
//     "Sr No.",
//     "Date",
//     "Time",
//     "Body Part",
//     "Pain Intensity",
//     "Comments",
//   ]);

//   data.forEach((item, index) => {
//     const dateObject = dayjs(item?.created_at);
//     const formattedTime = dateObject.format("hh:mm A");

//     const actualDate = item?.date || "-";

//     const bodyPartName = "Overall";

//     const bodyParts =
//       item?.body_parts_data?.length === 0
//         ? ""
//         : item?.body_parts_data
//             ?.map((part) => {
//               return `${part?.side}-${part?.name}`;
//             })
//             .join(", ");

//     const painIntensity =
//       item?.global_pain_level !== null ? item?.global_pain_level : "N/A";

//     const comments =
//       item?.body_parts_data
//         .map((part) => {
//           return part?.description;
//         })
//         .join(", ") || "-";

//     csvData.push([
//       index + 1,
//       actualDate,
//       formattedTime,
//       bodyParts,
//       painIntensity,
//       comments,
//     ]);
//   });

//   // return csvData;
// };

const showMedicationText = (medication) => {
  if (medication === "no") {
    return "Medication missed";
  } else if (medication === "na") {
    return "Medication not prescribed";
  } else if (medication === "yes") {
    return "Medication taken";
  }
};

export const exportCsvData = (allData) => {
  const csvData = [];
  let srNo = 1;

  // Adding CSV headers
  csvData?.push([
    "Sr No.",
    "Date",
    "Time",
    "Body Part",
    "Duration",
    "Pain Intensity",
    "Medication",
    "Comments",
  ]);

  // Loop over allData
  allData?.forEach((data, index) => {
    const createdTime = data?.created_at
      ? dayjs(data?.created_at)?.format("hh:mm A")
      : "-";
    // Overall Body Part
    // if (data?.global_pain_level !== null) {
    //   const row = [
    //     srNo++,
    //     data?.date,
    //     createdTime,
    //     "Overall",
    //     data?.global_pain_level,
    //     "-",
    //   ];
    //   csvData.push(row);
    // } else {
    //   const row = [srNo++, data?.date, createdTime, "", "-", "-"];
    //   csvData?.push(row);
    // }

    // Loop over body_parts_data
    data?.body_parts_data?.forEach((bodyPart, bodyPartIndex) => {
      const sleepLoss = showMedicationText(bodyPart?.medicine_adherence);
      const painDurationValue = data?.duration
        ? painDurationValues[data?.duration]
        : "-";

      const row = [
        srNo++,
        data?.date,
        createdTime,
        `${bodyPart?.side}-${bodyPart?.name}`,
        painDurationValue,
        bodyPart?.pain_scale,
        sleepLoss,
        bodyPart?.description,
      ];
      csvData.push(row);
    });
  });

  return csvData;
};

export const validateEmail = (inputEmail) => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(inputEmail);
};

export const clearContact = (phoneNumber) => {
  return phoneNumber.replace(/[+() ]/g, "");
};

export const formatPhoneNumber = (input) => {
  const numericInput = input.replace(/^1/, "").replace(/\D/g, ""); // Remove non-numeric characters from input

  if (numericInput.length === 0) {
    return "";
  }

  let formattedNumber = "+1 ";
  if (numericInput.length >= 1) {
    formattedNumber += `(${numericInput.slice(0, 3)}`;
  }
  if (numericInput.length >= 4) {
    formattedNumber += `) ${numericInput.slice(3, 6)}`;
  }
  if (numericInput.length >= 7) {
    formattedNumber += `-${numericInput.slice(6, 10)}`;
  }

  return formattedNumber;
};

export const isSupportedType = (filename) => {
  const extension = filename?.split(".")?.pop()?.toLowerCase();
  return ["jpg", "jpeg", "png", "gif", "mp4", "webm", "ogg"]?.includes(
    extension
  );
};

export const timeAgo = (createdAt) => {
  const formattedTime = dayjs(createdAt).fromNow();

  return formattedTime;
};

export const monthFilterOptions = [
  { title: "This Month", value: "this_month" },
  { title: "Last Month", value: "last_month" },
  // {
  //   title: dayjs().subtract(2, "month").format("MMMM YYYY"),
  //   value: dayjs().subtract(2, "month").format("YYYY-MM"),
  // },
];

const formatMonthYear = (date) => dayjs(date).format("MMMM 'YY");

// Get months between the current month and the enrollment month
const getMonthsTillEnrollment = (enrolledOn) => {
  const enrollmentDate = dayjs(enrolledOn);
  const months = [];

  let currentDate = dayjs().subtract(1, "month");

  while (
    currentDate.isAfter(enrollmentDate) ||
    currentDate.isSame(enrollmentDate, "month")
  ) {
    months.push({
      title: formatMonthYear(currentDate),
      value: currentDate.format("YYYY-MM"),
    });
    currentDate = currentDate.subtract(1, "month"); // Move back one month
  }

  return months;
};

export const reportMonthFilterOptions = (enrolledOn) => {
  return [
    { title: "Month to date", value: "this_month" },
    ...getMonthsTillEnrollment(enrolledOn),
  ];
};

export const calculateAppStatus = (firstLogin, lastAppOpened) => {
  const currentDate = dayjs();
  const daysDifference = lastAppOpened
    ? currentDate.diff(dayjs(lastAppOpened), "day")
    : null;

  if (!firstLogin && !lastAppOpened) {
    return "pending";
  }

  if (daysDifference > 7) {
    return "inactive";
  }

  return "active";
};

export const getMobileStatusIcon = (status) => {
  let statusIcon;
  switch (status) {
    case "pending":
      statusIcon = <PendingIcon />;
      break;

    case "inactive":
      statusIcon = <InactiveIcon />;
      break;

    case "active":
      statusIcon = <ActiveIcon />;
      break;

    default:
      statusIcon = <ActiveIcon />;
      break;
  }

  return statusIcon;
};

export const getCptCodeSubtitleText = (cptCode) => {
  let text;
  switch (cptCode) {
    case "98975":
      text = "Remote Therapeutic Monitoring Initial Setup";
      break;
    case "98977":
      text = "Device Supply for Musculoskeletal System Monitoring";
      break;
    case "98980":
      text = "Initial 20 min of treatment";
      break;
    case "98981":
      text = "Additional 20 min of treatment";
      break;
    default:
      break;
  }

  return text;
};

export const expenseTypeListItems = [
  {
    key: "transportExpenses",
    label: "Transport Expenses",
  },
  {
    key: "medicalExpenses",
    label: "Medical Expenses",
  },
  {
    key: "salaryLoss",
    label: "SalaryLoss",
  },
  {
    key: "others",
    label: "Others",
  },
];

export const caseTypeOptions = [
  { title: "Personal Injury", value: "personal injury" },
  { title: "Auto Accident", value: "Auto accident" },
  { title: "Slip & Fall", value: "slip-fall" },
  { title: "Premises Liability", value: "premises liability" },
  { title: "Dog Bite", value: "dog bite" },
  { title: "Pedestrian Accident", value: "pedestrian accident" },
  { title: "Other", value: "other" },
];

export const caseTypeName = {
  "personal injury": "Personal Injury",
  "Auto accident": "Auto Accident",
  "slip-fall": "Slip & Fall",
  "premises liability": "Premises Liability",
  "dog bite": "Dog Bite",
  "pedestrian accident": "Pedestrian Accident",
  other: "Other",
};

export const caseTypeValues = {
  "Personal Injury": "personal injury",
  "Auto Accident": "Auto accident",
  "Slip & Fall": "slip-fall",
  "Premises Liability": "premises liability",
  "Dog Bite": "dog bite",
  "Pedestrian Accident": "pedestrian accident",
  Other: "other",
};

export const findOptionByValue = (value) =>
  caseTypeOptions.find((option) => option.value === value) || null;

export const getNotificationText = (data) => {
  let displayText = "";

  if (data?.log_type === "appointment_update") {
    const { appointment_status, appointment_title } = data;
    displayText = (
      <span className={styles.lightText}>
        {appointment_status} {appointment_title.toLowerCase()}
      </span>
    );
  } else if (data?.log_type === "logged_in") {
    displayText = <span className={styles.lightText}>logged in</span>;
  } else if (data?.log_type === "document_uploaded") {
    displayText = (
      <span className={styles.lightText}>
        {data?.multiple_documents
          ? `uploaded documents`
          : `uploaded a document`}
      </span>
    );
  } else if (data?.log_type === "pain_logged") {
    displayText = <span className={styles.lightText}>logged pain</span>;
  } else if (data?.log_type === "case_created") {
    const { client } = data;
    displayText = (
      <span className={styles.lightText}>
        {" "}
        created a case for {client?.name}
      </span>
    );
  } else if (data?.log_type === "case_status_update") {
    const { client, case_status } = data;
    displayText = (
      <span className={styles.lightText}>
        {" "}
        updated case status to {case_status} for {client?.name}
      </span>
    );
  } else if (data?.log_type === "expense_added") {
    displayText = (
      <span className={styles.lightText}> added a new expense</span>
    );
  }
  return displayText;
};

export const getAlertNotificationText = (data) => {
  let displayText;

  if (data?.log_type === "no_pain_logged") {
    displayText = (
      <span>has not logged pain for the last {data?.number_of_days} days</span>
    );
  } else if (data?.log_type === "no_activity") {
    displayText = (
      <span>
        has not performed any activity for the last {data?.number_of_days} days
      </span>
    );
  } else if (data?.log_type === "missed_appointment_status") {
    displayText = (
      <span>
        has not updated the status of last {data?.number_of_days} appointments
      </span>
    );
  } else {
    displayText = "Unknown Log Type";
  }

  return displayText;
};

export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 byte";
  }
  return bytes;
}

export const fileTypes = (type) => {
  if (type === "application/pdf") {
    return pdfImg;
  } else if (
    type === "image/png" ||
    type === "image/jpg" ||
    type === "image/jpeg"
  ) {
    return Img;
  } else {
    return docImg;
  }
};

export function caseStatusType(type) {
  if (type?.toLowerCase() === "open" || type?.toLowerCase() === "sent") {
    return "primary";
  } else if (type?.toLowerCase() === "closed") {
    return "error";
  } else if (type?.toLowerCase() === "on hold") {
    return "warning";
  } else {
    return "success";
  }
}

export function patientStatus(status) {
  if (status === "active") {
    return "primary";
  } else {
    return "warning";
  }
}

export function formatCaseTypeText(text) {
  // Replace underscores and hyphens with space and capitalize the first letter of each word
  const formattedText = text
    .replace(/[_-]/g, " & ")
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  return formattedText;
}

export function notificationStatusColor(status) {
  if (status.toLowerCase() === "sent") {
    return "success";
  } else if (status.toLowerCase() === "scheduled") {
    return "primary";
  } else if (status.toLowerCase() === "on hold") {
    return "warning";
  } else {
    return "error";
  }
}

// export function referralStatusColor(status) {
//   let color;
//   if (status.toLowerCase() === "sent") {
//     color = "#2196F3";
//   } else if (status.toLowerCase() === "completed") {
//     color = "#2E7D32";
//   } else {
//     color = "#FAD44D";
//   }
//   return color;
// }

export const caseValueColor = (value) => {
  let color;
  switch (value) {
    case "high":
      color = "#D32F2F";
      break;
    case "low":
      color = "#649F67";
      break;
    case "moderate":
      color = "#F29244";
      break;
    default:
      break;
  }

  return color;
};

export const caseValueBgColor = (value) => {
  let color;
  switch (value) {
    case "high":
      color = "rgba(211, 47, 47, 0.15)";
      break;
    case "low":
      color = "rgba(100, 159, 103, 0.15)";
      break;
    case "moderate":
      color = "rgba(242, 146, 68, 0.15)";
      break;
    default:
      break;
  }

  return color;
};

export const notesOptions = [
  {
    title: "All",
    value: "all",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
  {
    title: "Session",
    value: "timer",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
  {
    title: "Custom Notes",
    value: "custom_note",
    borderColor: "#FF852D",
    bgColor: "rgba(255, 133, 45, 0.08)",
    hoverBgColor: "#FF852D",
  },
  // {
  //   title: "System",
  //   value: "system",
  //   bgColor: "#FFF8EC",
  //   borderColor: "#F1B62E",
  //   hoverBgColor: "#D29836",
  // },
  {
    title: "Enrollment",
    value: "enrollment",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
  {
    title: "Medical History",
    value: "medical_history",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
];

export const getNotesColor = (value) => {
  let color;
  switch (value) {
    case "timer":
      color = "#0C80F3";
      break;
    case "custom_note":
      color = "#FF852D";
      break;
    case "enrollment":
      color = "#0C80F3";
      break;

    default:
      color = "#0C80F3";
      break;
  }

  return color;
};

export const getNotesBackgroundColor = (value) => {
  let bgcolor;
  switch (value) {
    case "timer":
      bgcolor = "rgba(12, 128, 243, 0.08)";
      break;
    case "custom_note":
      bgcolor = "rgba(255, 133, 45, 0.08)";
      break;
    case "enrollment":
      bgcolor = "rgba(12, 128, 243, 0.08)";
      break;

    default:
      bgcolor = "rgba(12, 128, 243, 0.08)";
      break;
  }

  return bgcolor;
};

export const caseStatusColor = (value) => {
  let color;
  switch (value) {
    case "closed":
      color = "#D32F2F";
      break;
    case "settled":
      color = "#649F67";
      break;
    case "on hold":
      color = "#F29244";
      break;
    case "open":
      color = "#0C80F3";
      break;
    default:
      break;
  }

  return color;
};

export const caseStatusBgColor = (value) => {
  let color;
  switch (value) {
    case "closed":
      color = "rgba(211, 47, 47, 0.15)";
      break;
    case "settled":
      color = "rgba(100, 159, 103, 0.15)";
      break;
    case "on hold":
      color = "rgba(242, 146, 68, 0.15)";
      break;
    case "open":
      color = "rgba(12, 128, 243, 0.15)";
      break;
    default:
      break;
  }

  return color;
};

export const caseLogsCategory = {
  pain_logged: "General",
  document_uploaded: "General",
  appointment_update: "Appointment",
  case_status_update: "System",
  case_created: "System",
  custom_note: "Note",
  expense_added: "Expense",
};

export function stepperIcon(type) {
  if (type === "appointment_update") {
    return "#3DC583";
  } else if (type === "custom_note") {
    return "#F3AF3D";
  } else if (type === "expense_added") {
    return "#F54949";
  } else {
    return "#0C80F3";
  }
}

const log_type_title_config = {
  pain_logged: "Pain Logged",
  case_created: "Case Created",
  appointment_update: "",
  case_status_update: "Case Status changed to ",
  document_uploaded: "Uploaded a Document",
  chat_activity: "Client replied in chat",
};

export const expenseTypeValuesStatement = {
  other: "Other Expense",
  transport_expense: "Transport Expense",
  medical_expense: "Medical Expense",
  salary_loss: "Salary Loss",
};

export const painDurationValues = {
  all_day: "All Day",
  hours_1_3: "1-3 Hours",
  hours_3_10: "3-10 Hours",
  hours_10_20: "10-20 Hours",
};

export const renderStepContent = (step) => {
  switch (step.log_type) {
    case "custom_note":
      return step.log_text;

    case "chat_activity":
    case "pain_logged":
    case "case_created":
    case "document_uploaded":
      return log_type_title_config[step.log_type];
    case "case_status_update":
      return `${log_type_title_config[step.log_type]} ${step.case_status}`;
    case "appointment_update":
      return `${step.appointment_status} ${step.appointment_title}`;
    case "expense_added":
      return `Added a ${expenseTypeValuesStatement[step?.expense_type]} of $${
        step?.expense_amount
      }`;
    default:
      return step.log_type;
  }
};

export const painLevelBackgroundColor = (painLevel) => {
  let bgColor;
  switch (painLevel) {
    case 0:
      bgColor = "#43A762";
      break;
    case 1:
      bgColor = "#9ECA46";
      break;
    case 2:
      bgColor = "#E6E62F";
      break;
    case 3:
      bgColor = "#E6E630";
      break;
    case 4:
      bgColor = "#E6E62F";
      break;
    case 5:
      bgColor = "#E8C929";
      break;
    case 6:
      bgColor = "#E9AB22";
      break;
    case 7:
      bgColor = "#E8921B";
      break;
    case 8:
      bgColor = "#E67612";
      break;
    case 9:
      bgColor = "#E85014";
      break;
    case 10:
      bgColor = "#E91416";
      break;
    default:
      return "#000";
  }

  return bgColor;
};

export const lastMonthCreatedCases = (cases, month) => {
  const expectedMonth = dayjs().subtract(month, "month");

  const casesCreatedLastMonth = cases?.filter((caseItem) =>
    dayjs(caseItem.created_at).isAfter(expectedMonth)
  );

  return casesCreatedLastMonth;
};

export const groupedEntriesFunc = (timeEntriesArr) => {
  return timeEntriesArr?.reduce((acc, entry) => {
    const date = dayjs(entry?.date).format("YYYY-MM-DD");
    const timeInSeconds = entry?.time_in_seconds;
    if (!acc[date]) {
      acc[date] = { totalTime: 0, entries: [] };
    }
    acc[date].entries?.push(entry);
    acc[date].totalTime += timeInSeconds;

    return acc;
  }, {});
};

const avatarColors = [
  "#B2D7FD",
  "#FECDAA",
  "#A4EDC9",
  "#D5BDF7",
  "#F6A0A0",
  "#F6D68D",
];

export const getRandomAvatarBackgroundColor = () => {
  return avatarColors[Math.floor(Math.random() * avatarColors.length)];
};

export const getCptDateRanges = (filterValue) => {
  let startDate, endDate;

  if (filterValue === "this_month") {
    startDate = dayjs()?.startOf("month")?.format("YYYY-MM-DD");
    endDate = dayjs().format("YYYY-MM-DD");
  } else if (filterValue === "last_month") {
    startDate = dayjs()
      ?.subtract(1, "month")
      ?.startOf("month")
      ?.format("YYYY-MM-DD");
    endDate = dayjs()
      ?.subtract(1, "month")
      ?.endOf("month")
      ?.format("YYYY-MM-DD");
  }

  return { startDate, endDate };
};

export const getReportDateRanges = (filterValue) => {
  let startDate, endDate;

  if (filterValue === "this_month") {
    startDate = dayjs().startOf("month").format("YYYY-MM-DD");
    endDate = dayjs().format("YYYY-MM-DD");
  } else if (filterValue === "last_month") {
    startDate = dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
  } else if (filterValue.length === 7) {
    // Example for "2024-06" format
    startDate = dayjs(filterValue + "-01")
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = dayjs(filterValue + "-01")
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  return { startDate, endDate };
};

export const getEvidenceDownloadDateRanges = (filterValue, enrolledDate) => {
  let startDate, endDate;

  if (filterValue === "this_month") {
    startDate = dayjs().startOf("month").format("YYYY-MM-DD");
    endDate = dayjs().format("YYYY-MM-DD");
  } else {
    startDate = dayjs(`${filterValue}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    endDate = dayjs(`${filterValue}-01`).endOf("month").format("YYYY-MM-DD");
  }

  if (enrolledDate && dayjs(enrolledDate).isSame(startDate, "month")) {
    startDate = dayjs(enrolledDate).format("YYYY-MM-DD");
  }

  return { startDate, endDate };
};

export const timeFormat = (time) => {
  const formattedTime = dayjs(time)?.format("hh:mm A");
  return formattedTime;
};

export const dateFormat = (date) => {
  const formattedDate = dayjs(date)?.format("MM/DD/YYYY");
  return formattedDate;
};

export const dateFormatHyphen = (date) => {
  const formattedDate = dayjs(date).format("MM-DD-YYYY");
  return formattedDate;
};

export const dateTimeFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString)?.format("hh:mm A, D MMMM");
  return formattedDateTime;
};

export const shortDateTimeFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString)?.format("hh:mm A, D MMM");
  return formattedDateTime;
};

export const timeDateFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("D MMMM, hh:mm A");
  return formattedDateTime;
};

export const shortDateFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("D MMM, hh:mm A");
  return formattedDateTime;
};
export const onlyDateFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("MMM D, YYYY");
  return formattedDateTime;
};
export const dateWithoutYearFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("D MMM");
  return formattedDateTime;
};

export const extractDate = (date) => {
  const stringDate = dayjs(date).format("DD");
  return stringDate;
};

export const extractMonth = (date) => {
  const month = dayjs(date).format("MMM");
  return month;
};

export const parseAndMergeBodyParts = (bodyPartsArrays) => {
  let mergedBodyParts = [];

  bodyPartsArrays?.forEach((bodyPart) => {
    mergedBodyParts = [...mergedBodyParts, ...bodyPart];
  });

  return mergedBodyParts;
};

export function displayExpenseAmounts(expenseDetails) {
  let transportExpenseAmount = 0;
  let medicalExpenseAmount = 0;
  let salaryLossAmount = 0;
  let otherAmount = 0;

  expenseDetails?.forEach((expense) => {
    switch (expense.expense_type) {
      case "transport_expense":
        transportExpenseAmount += expense.total_amount;
        break;
      case "medical_expense":
        medicalExpenseAmount += expense.total_amount;
        break;
      case "salary_loss":
        salaryLossAmount += expense.total_amount;
        break;
      default:
        otherAmount += expense.total_amount;
        break;
    }
  });

  return {
    transportExpenseAmount,
    medicalExpenseAmount,
    salaryLossAmount,
    otherAmount,
  };
}

export function showExpenseTypeName(expenseType) {
  switch (expenseType) {
    case "transport_expense":
      return "Transport";
    case "medical_expense":
      return "Medical";
    case "salary_loss":
      return "Salary Loss";
    case "other":
      return "Other";
    default:
      break;
  }
}
export function showNotesType(note) {
  let noteType;
  switch (note) {
    case "custom_note":
      noteType = "Custom Notes";
      break;
    case "medical_history":
      noteType = "Medical History";
      break;
    case "enrollment":
      noteType = "Enrollment";
      break;
    default:
      noteType = "Enrollment";
      break;
  }

  return noteType;
}

export const expenseTypeValues = {
  transport_expense: "transportExpenses",
  medical_expense: "medicalExpenses",
  salary_loss: "salaryLoss",
  other: "others",
};
