import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import {
  Box,
  Button,
  Grid,
  Typography,
  Link,
  Avatar,
  Stack,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MccLogo from "../../../components/MccLogo";
import styled from "@emotion/styled";
import { AddPractiseIcon } from "../../../assets/constants";
import Textfield from "../../../common/Textfield";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { stringAvatar, validateEmail } from "../../../utils/helpers";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  createWorkspaceFunc,
  inviteAgentFunc,
  resetAgentInvite,
  resetCreateWorkSpace,
} from "../../../actions/Agency";
import AlertMessage from "../../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";

const StyledEmailedBtn = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  border: "1px solid rgba(192, 192, 192, 0.88)",
  textTransform: "none",
  color: "#000!important",
  fontWeight: "400",
  padding: "8px",
  borderRadius: "4px",
  cursor: "pointer",
}));

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.?])[A-Za-z\d!@#$%^&*.?]{8,}$/;

const JoinWorkspace = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const organizationId = Cookies.get("org_id_vita");

  const [showPractiseBox, setShowPractiseBox] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenSuccessMessage(false);
  };

  // const {
  //   state: { agent_data },
  // } = useLocation();
  const { state } = useLocation();

  const agentData = state && state?.agent_data;
  const existingAgencies = state && state?.existing_agencies;

  const createWorkspace = useSelector((state) => state.createWorkspace);
  const {
    loading: loadingAgency,
    error: errorAgency,
    workspace,
  } = createWorkspace;

  const inviteAgent = useSelector((state) => state.inviteAgent);
  const {
    loading: loadingAgent,
    error: errorAgent,
    invitedAgent,
  } = inviteAgent;

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);
    if (!emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  useEffect(() => {
    if (state === null) {
      navigate(-1);
      return;
    }
    Cookies.set("route", "/join-workspace");
  }, []);

  useEffect(() => {
    if (existingAgencies && existingAgencies?.length) {
      setAgencies(existingAgencies);
    }
  }, [state]);

  const joinWorkspaceHandler = () => {
    Cookies.remove("route");
    dispatch(resetAgentInvite());
    navigate("/password-created", { state: { createAgentName: true } });
  };

  const showMorePractiseHandler = () => {
    setShowPractiseBox(true);
  };

  const closePractiseBoxHandler = () => {
    setShowPractiseBox(false);
    setEmail("");
    setPracticeName("");
    setOwnerName("");
  };

  const addPracticeHandler = () => {
    const agencyObj = {
      agency: {
        name: practiceName,
        primary_email: email,
      },
      organization_id: organizationId,
    };

    dispatch(createWorkspaceFunc(agencyObj));
    setEmailErrorMsg("");
  };

  useEffect(() => {
    if (!loadingAgency && !workspace?.ok) {
      setEmailErrorMsg(workspace?.message);
      setOpen(true);
    }
  }, [workspace]);

  useEffect(() => {
    if (!loadingAgent && !invitedAgent?.ok) {
      setEmailErrorMsg(invitedAgent?.message);
      setOpen(true);
      setShowPractiseBox(false);
      setEmail("");
      setPracticeName("");
      setOwnerName("");
    }
  }, [invitedAgent]);

  useEffect(() => {
    if (workspace?.data?.ok) {
      setAgencies((prevState) => [...prevState, workspace?.data?.agency]);
      const agentObj = {
        agency_id: workspace?.data?.agency?.id,
        send_credentials: true,
        agent_obj: {
          first_name: ownerName,
          email: email,
          role: "Owner",
          permissions: {
            access_patient_details: {
              create: true,
              read: true,
              edit: true,
              delete: true,
            },
            can_chat_client: true,
            can_access_billing: false,
          },
        },
      };
      dispatch(inviteAgentFunc(agentObj));
      dispatch(resetCreateWorkSpace());
    }
  }, [workspace]);

  useEffect(() => {
    if (invitedAgent?.ok) {
      setOpenSuccessMessage(true);
      setSuccessMessage(invitedAgent?.message);
      setEmailErrorMsg("");
      setShowPractiseBox(false);
      setEmail("");
      setPracticeName("");
      setOwnerName("");
    }
  }, [invitedAgent]);

  if (state === null) {
    return;
  }

  return (
    <>
      {!loadingAgency && emailErrorMsg && (
        <AlertMessage
          message={emailErrorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {openSuccessMessage && (
        <AlertMessage
          message={successMessage}
          severity="success"
          open={openSuccessMessage}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI padding="40px" paddingTop="40px">
          <Grid container component="main">
            <Box
              component="form"
              xs={12}
              display="flex"
              gap="32px"
              flexDirection="column"
              width="100%"
            >
              <Stack gap="32px">
                <Stack gap="16px">
                  <HeaderText
                    variant="h3"
                    fontSize={48}
                    textContent="Your Practices"
                  />
                  <HeaderText
                    variant="body1"
                    fontSize={16}
                    fontWeight={400}
                    color="#566875"
                    textContent="As a care management group you can setup & manage multiple practices"
                    // lineHeight="24px"
                  />
                </Stack>

                <Stack gap="16px">
                  {((existingAgencies && existingAgencies?.length) ||
                    agencies?.length) &&
                  !emailErrorMsg ? (
                    <HeaderText
                      variant="subtitle2"
                      fontSize="14px"
                      textContent="Existing practices associated to your email"
                      // lineHeight="24px"
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      color="rgba(0, 0, 0, 0.38)"
                      fontWeight={400}
                    >
                      Your email isn't linked to any practice. Use "Add
                      Practice" to set one up.
                    </Typography>
                  )}

                  {agencies?.map((item, index) => (
                    <StyledEmailedBtn key={index}>
                      <Avatar
                        variant="rounded"
                        sx={{ bgcolor: "#B0D6FE" }}
                        {...stringAvatar(item?.name)}
                      />
                      <Stack>
                        <Typography variant="body1" fontWeight={500}>
                          {item?.name}
                        </Typography>
                        <Typography variant="caption" color="secondary">
                          {item?.primary_email && item?.primary_email} |{" "}
                          {item?.id && ` Practice ID : ${item?.id}`}
                        </Typography>
                      </Stack>
                    </StyledEmailedBtn>
                  ))}

                  {!showPractiseBox && (
                    <Button
                      size="large"
                      onClick={showMorePractiseHandler}
                      color="secondary"
                      sx={{
                        bgcolor: "#F4F6F8",
                        textTransform: "capitalize",
                        fontSize: "16px",
                      }}
                      startIcon={<AddPractiseIcon />}
                    >
                      Add Practice
                    </Button>
                  )}
                </Stack>
              </Stack>

              {showPractiseBox && (
                <Stack gap="16px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontWeight={500}>
                      Add Practice
                    </Typography>
                    <Button variant="text" onClick={closePractiseBoxHandler}>
                      Cancel
                    </Button>
                  </Box>
                  <Textfield
                    variant="outlined"
                    name="practice_name"
                    label="Practice name"
                    value={practiceName}
                    onChange={(e) => setPracticeName(e.target.value)}
                    placeholder="Health care experts"
                    width="100%"
                  />
                  <Textfield
                    name="owner_email"
                    label="Owner's Email"
                    placeholder="example@gmail.com"
                    variant="outlined"
                    value={email}
                    onChange={emailChangeHandler}
                    errorContent={emailError && "Enter valid email"}
                    helperText={emailError && "Enter valid email"}
                  />
                  <Textfield
                    variant="outlined"
                    name="owner_name"
                    label="Owner's Name"
                    value={ownerName}
                    onChange={(e) => setOwnerName(e.target.value)}
                    placeholder="John Doe"
                    width="100%"
                  />

                  <Typography variant="body2" color="secondary" fontSize="15px">
                    Password will be automatically generated and sent to your
                    email address.
                  </Typography>
                </Stack>
              )}

              <Box display="flex" flexDirection="column" gap="16px">
                <LoadingButton
                  fullWidth
                  loading={
                    showPractiseBox ? loadingAgency || loadingAgent : false
                  }
                  variant="contained"
                  sx={{ padding: "8px 22px", fontSize: 15 }}
                  disabled={
                    showPractiseBox
                      ? !practiceName || !email || !ownerName
                      : !agencies?.length
                  }
                  onClick={
                    showPractiseBox ? addPracticeHandler : joinWorkspaceHandler
                  }
                >
                  {showPractiseBox ? "Add Practice" : "Continue"}
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default JoinWorkspace;
