import React from "react";

const FlashIcon = ({ color = "#212121" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M4.56733 9.96065H6.88483V15.3607C6.88483 16.6207 7.56733 16.8757 8.39983 15.9307L14.0773 9.48065C14.7748 8.69315 14.4823 8.04065 13.4248 8.04065H11.1073V2.64066C11.1073 1.38066 10.4248 1.12566 9.59233 2.07066L3.91483 8.52065C3.22483 9.31565 3.51733 9.96065 4.56733 9.96065Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlashIcon;
