import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { getIconColor, getIconName, stringAvatar } from "../../utils/helpers";

const PractiseBoxCard = ({
  icon,
  src,
  title,
  practiseId,
  open,
  switchWorkspaceHandler,
  mb = "12px",
  showEllipsis = false,
  border = false,
  logoType,
  iconName,
  iconColor,
  logoUrl,
}) => {
  return (
    <Box
      display="flex"
      padding="8px"
      alignItems="center"
      justifyContent="space-between"
      gap="12px"
      mb={mb}
      width="100%"
      borderRadius="4px"
      onClick={switchWorkspaceHandler}
      sx={{
        cursor: "pointer",
        boxShadow: open && border ? "inset 0 0 0 1px #0C80F3" : "",
        "&:hover": {
          background: "#F4F6F8",
        },
      }}
    >
      <Box display="flex" alignItems="center" gap="12px">
        {logoType === "custom" ? (
          <Box width="40px" height="40px" borderRadius="4px" overflow="hidden">
            <img width="100%" height="100%" src={logoUrl} alt="practise logo" />
          </Box>
        ) : iconName === null || iconName === "avatar_icon" ? (
          <Avatar
            variant="rounded"
            sx={{ background: getIconColor(iconColor) }}
            {...stringAvatar(title)}
          />
        ) : (
          <Box
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: getIconColor(iconColor),
            }}
            borderRadius="4px"
          >
            {getIconName(iconName)}
          </Box>
        )}

        <Stack>
          {showEllipsis ? (
            <Tooltip title={title} placement="top-end">
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{
                  textOverflow: "ellipsis !important",
                  whiteSpace: "nowrap !important",
                  width: "130px !important",
                  overflow: "hidden !important",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body1" fontWeight={500}>
              {title}
            </Typography>
          )}
          <Typography variant="caption" color="secondary">
            Practice ID : {practiseId}
          </Typography>
        </Stack>
      </Box>

      <IconButton
        sx={{ padding: 0 }}
        aria-controls={open ? "workspace-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // disableRipple
      >
        {icon}
      </IconButton>
    </Box>
  );
};

export default PractiseBoxCard;
