import Cookies from "js-cookie";
import {
  ADD_NEW_CASE_FAIL,
  ADD_NEW_CASE_REQUEST,
  ADD_NEW_CASE_SUCCESS,
  ADD_NOTE_TO_CASE_FAIL,
  ADD_NOTE_TO_CASE_REQUEST,
  ADD_NOTE_TO_CASE_SUCCESS,
  ADD_QUESTION_TO_CASE_FAIL,
  ADD_QUESTION_TO_CASE_REQUEST,
  ADD_QUESTION_TO_CASE_SUCCESS,
  DELETE_CASE_FAIL,
  DELETE_CASE_REQUEST,
  DELETE_CASE_SUCCESS,
  GET_AGENCY_PATIENTS_FAIL,
  GET_AGENCY_PATIENTS_REQUEST,
  GET_AGENCY_PATIENTS_SUCCESS,
  GET_ALL_CASES_FAIL,
  GET_ALL_CASES_REQUEST,
  GET_ALL_CASES_SUCCESS,
  GET_A_CASE_BY_CID_FAIL,
  GET_A_CASE_BY_CID_REQUEST,
  GET_A_CASE_BY_CID_SUCCESS,
  GET_CASE_BY_AGENT_FAIL,
  GET_CASE_BY_AGENT_REQUEST,
  GET_CASE_BY_AGENT_SUCCESS,
  GET_CASE_CLIENT_DOCUMENTS_FAIL,
  GET_CASE_CLIENT_DOCUMENTS_REQUEST,
  GET_CASE_CLIENT_DOCUMENTS_SUCCESS,
  GET_CHART_PAINLOGS_FAIL,
  GET_CHART_PAINLOGS_REQUEST,
  GET_CHART_PAINLOGS_SUCCESS,
  GET_CLIENT_PAINLOGS_FAIL,
  GET_CLIENT_PAINLOGS_REQUEST,
  GET_CLIENT_PAINLOGS_SUCCESS,
  GET_DASHBOARD_ANALYTICS_FAIL,
  GET_DASHBOARD_ANALYTICS_REQUEST,
  GET_DASHBOARD_ANALYTICS_SUCCESS,
  GET_HIGHRISK_CASES_FAIL,
  GET_HIGHRISK_CASES_REQUEST,
  GET_HIGHRISK_CASES_SUCCESS,
  GET_PIE_CHART_STATS_FAIL,
  GET_PIE_CHART_STATS_REQUEST,
  GET_PIE_CHART_STATS_SUCCESS,
  GET_UNPAGINATED_CASES_FAIL,
  GET_UNPAGINATED_CASES_REQUEST,
  GET_UNPAGINATED_CASES_SUCCESS,
  GET_UNREAD_CHAT_MESSAGES_FAIL,
  GET_UNREAD_CHAT_MESSAGES_REQUEST,
  GET_UNREAD_CHAT_MESSAGES_SUCCESS,
  RESET_ADD_NOTE_TO_CASE,
  RESET_ADD_QUESTION_TO_CASE,
  RESET_ALL_CASES,
  RESET_A_CASE_BY_CID,
  RESET_CASE_CLIENTS_DOCUMENTS,
  RESET_DELETE_CASE,
  RESET_PIE_CHART_STATS,
  RESET_UNPAGINATED_CASES,
  UPDATE_CASE_FAIL,
  UPDATE_CASE_REQUEST,
  UPDATE_CASE_RESET,
  UPDATE_CASE_SUCCESS,
} from "../../assets/constants/caseConstants";
import axios from "axios";
import { BASE_URL } from "..";

// ================== GET ALL CASES  =========================
export const getAllCasesFunc = (page, pageSize, search) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_CASES_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;
      if (search) {
        response = await axios.get(
          `${BASE_URL}/patient/get-list?agency_id=${agencyId}&search=${search}`,
          config
        );
      } else {
        response = await axios.get(
          `${BASE_URL}/patient/get-list?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );
      }

      dispatch({ type: GET_ALL_CASES_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_CASES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET ALL UNPAGINATED CASES  =========================
export const getAllAgencyPatientsFunc = (agencyIds) => async (dispatch) => {
  try {
    dispatch({ type: GET_AGENCY_PATIENTS_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/get-count?${agencyIds}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_AGENCY_PATIENTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_AGENCY_PATIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET ALL UNPAGINATED CASES  =========================
export const getUnpaginatedPatientsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_UNPAGINATED_CASES_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/get-list-unpaginated?agency_id=${agencyId} `,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_UNPAGINATED_CASES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_UNPAGINATED_CASES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET A CASE BY CASE ID  =========================
export const getCaseDetailByCaseId = (patientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_A_CASE_BY_CID_REQUEST });

    const token = Cookies.get("token");
    // const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/patient/get?patient_id=${patientId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: GET_A_CASE_BY_CID_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_A_CASE_BY_CID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetCaseDetailByCaseId = () => (dispatch) =>
  dispatch({
    type: RESET_A_CASE_BY_CID,
  });

// ================== GET A CASE BY AGENT ACTION  =========================
export const getCaseByAgentFunc = (agentId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CASE_BY_AGENT_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/case/get-by-agent?agent_id=${agentId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: GET_CASE_BY_AGENT_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: GET_CASE_BY_AGENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET CASE CLIENTS DOCUMENTS ACTIONS  =========================
export const getCaseClientDocsFunc = (caseId, clientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CASE_CLIENT_DOCUMENTS_REQUEST });

    const token = Cookies.get("token");
    // const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/storage/get-client-and-case-documents?client_id=${clientId}&case_id=${caseId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_CASE_CLIENT_DOCUMENTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CASE_CLIENT_DOCUMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET CLIENT PAIN LOGS ACTIONS  =========================
export const getClientPainlogsFunc =
  (patientId, startDate, endDate, selectedPain) => async (dispatch) => {
    try {
      dispatch({ type: GET_CLIENT_PAINLOGS_REQUEST });

      const token = Cookies.get("token");
      // const agencyId = Cookies.get("agency_id_vita");
      if (token) {
        // userInfo = JSON.parse(userInfo);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/pain-entry/get?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&filter=${selectedPain}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_CLIENT_PAINLOGS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CLIENT_PAINLOGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== GET CHART PAIN LOGS ACTIONS  =========================
export const getChartPainlogsFunc =
  (patientId, startDate, endDate, selectedPain) => async (dispatch) => {
    try {
      dispatch({ type: GET_CHART_PAINLOGS_REQUEST });

      const token = Cookies.get("token");
      // const agencyId = Cookies.get("agency_id_vita");
      if (token) {
        // userInfo = JSON.parse(userInfo);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/pain-entry/get?patient_id=${patientId}&start_date=${startDate}&end_date=${endDate}&filter=${selectedPain}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_CHART_PAINLOGS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CHART_PAINLOGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== ADD NEW CASE ACTIONS  =========================
export const addNewCaseFunc = (newCaseData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_CASE_REQUEST });

    const token = Cookies.get("token");
    // const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/case/create`,
        newCaseData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ADD_NEW_CASE_SUCCESS,
        payload: response?.data,
      });

      // dispatch({ type: GET_ALL_CASES_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: ADD_NEW_CASE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== UPDATE CASE ACTIONS  =========================
export const updateCaseFunc = (patientData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CASE_REQUEST });

    const token = Cookies.get("token");
    // const agencyId = Cookies.get("agency_id_vita");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/update`,
        patientData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: UPDATE_CASE_SUCCESS,
        payload: response?.data,
      });

      // dispatch({ type: GET_ALL_CASES_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CASE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== DELETE CASE ACTIONS  =========================
export const deleteCaseFunc = (patientId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CASE_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/patient/delete`,
        {
          patient_id: patientId,
        },
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: DELETE_CASE_SUCCESS,
      });

      // dispatch({ type: GET_ALL_CASES_SUCCESS, payload: response?.data });
    }
  } catch (error) {
    dispatch({
      type: DELETE_CASE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET DASHBOARD ANALYTICS ACTIONS  =========================
export const getDashboardAnalyticsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DASHBOARD_ANALYTICS_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agent/get-dashboard-analytics`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_DASHBOARD_ANALYTICS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_ANALYTICS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET HIGH RISK CASES ACTIONS  =========================
export const getHighRiskCasesFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_HIGHRISK_CASES_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agent/get-high-risk-cases`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_HIGHRISK_CASES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_HIGHRISK_CASES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET DASHBOARD UNREAD CHAT MESSAGES ACTIONS  =========================
export const getUnreadChatMessagesFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_UNREAD_CHAT_MESSAGES_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/agent/get-unread-chats`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_UNREAD_CHAT_MESSAGES_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_UNREAD_CHAT_MESSAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== GET PIE CHART STATS ACTIONS  =========================
export const getPieChartStatsFunc =
  (clientId, caseId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: GET_PIE_CHART_STATS_REQUEST });

      const token = Cookies.get("token");
      if (token) {
        // userInfo = JSON.parse(userInfo);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/pain-entry/get-stats?start_date=${startDate}
          &end_date=${endDate}&client_id=${clientId}&case_id=${caseId}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_PIE_CHART_STATS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_PIE_CHART_STATS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ================== ADD QUESTION TO CASE ACTIONS  =========================
export const addQuestionToCaseFunc = (newQuestionData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_QUESTION_TO_CASE_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      // userInfo = JSON.parse(userInfo);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/intake/add-questions-to-case`,
        newQuestionData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ADD_QUESTION_TO_CASE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_QUESTION_TO_CASE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== ADD NOTE TO CASE ACTIONS  =========================
export const addNoteToCaseFunc = (noteData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NOTE_TO_CASE_REQUEST });

    const token = Cookies.get("token");
    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/case/add-note`,
        noteData,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ADD_NOTE_TO_CASE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_NOTE_TO_CASE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetAddNoteToCase = () => async (dispatch) => {
  dispatch({ type: RESET_ADD_NOTE_TO_CASE });
};

export const resetNewCase = () => async (dispatch) => {
  dispatch({ type: ADD_NEW_CASE_SUCCESS, payload: { ok: false } });
};

export const resetCaseClientDocs = () => async (dispatch) => {
  dispatch({ type: RESET_CASE_CLIENTS_DOCUMENTS, payload: { ok: false } });
};

export const resetDeleteCase = () => async (dispatch) => {
  dispatch({
    type: RESET_DELETE_CASE,
  });
};

export const resetUpdateCase = () => (dispatch) =>
  dispatch({
    type: UPDATE_CASE_RESET,
  });

export const resetAllCases = () => async (dispatch) =>
  dispatch({
    type: RESET_ALL_CASES,
  });

export const resetUnpaginatedCases = () => async (dispatch) =>
  dispatch({
    type: RESET_UNPAGINATED_CASES,
  });

export const resetPieChartStats = () => async (dispatch) => {
  dispatch({ type: RESET_PIE_CHART_STATS });
};

export const resetClientPainlogs = () => async (dispatch) => {
  dispatch({ type: GET_CLIENT_PAINLOGS_SUCCESS, payload: { ok: false } });
};

export const resetAddQuestionToCase = () => async (dispatch) => {
  dispatch({ type: RESET_ADD_QUESTION_TO_CASE });
};
