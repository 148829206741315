import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Stack,
  Typography,
  Grid,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  InputAdornment,
  Autocomplete,
  IconButton,
  Divider,
  Avatar,
  Icon,
} from "@mui/material";

import Style from "./style";
import Button from "../../../../common/Button";
import Textfield from "../../../../common/Textfield";
import CardUI from "../../../../common/Card";
import AlertMessage from "../../../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  clearContact,
  formatPhoneNumber,
  stringAvatar,
  validateEmail,
} from "../../../../utils/helpers";
import {
  updateAgencyFunc,
  getAllAgenciesListFunc,
  getAgencyDetailFunc,
  resetUpdateAgencyFunc,
} from "../../../../actions/Agency";
import { statesWithCities } from "../../../../utils/helpers/states_with_cities";
import { segmentTrack } from "../../../../utils/helpers/tracking_utils";
import SkeletonGrid from "../../../../components/SkeletonGrid";
import GeneralSkeleton from "../../../../components/GeneralSkeleton";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";
import styled from "@emotion/styled";
import {
  AddLogo,
  AddLogoIcon,
  DeleteIcon,
  DeleteLogoIcon,
} from "../../../../assets/constants";
import VitatraxIcon from "../../../../icons/VitatraxIcon";
import HospitalIcon from "../../../../icons/HospitalIcon";
import BuildingIcon from "../../../../icons/BuildingIcon";
import FlashIcon from "../../../../icons/FlashIcon";
import HomeIcon from "../../../../icons/HomeIcon";
import StarIcon from "../../../../icons/StarIcon";
import BriefCaseIcon from "../../../../icons/BriefCaseIcon";
import ProfileModal from "../../../../components/ProfileModal";
import DialogOverlay from "../../../../components/DialogOverlay";
import { Cropper } from "react-cropper";
import ZoominIcon from "../../../../icons/ZoominIcon";
import ZoomoutIcon from "../../../../icons/ZoomoutIcon";
import { LoadingButton } from "@mui/lab";

const states = Object.keys(statesWithCities);

const logoIcons = [
  { value: "avatar_icon", icon: false },
  { iconName: <VitatraxIcon />, value: "vitatrax_icon", icon: true },
  { iconName: <HospitalIcon />, value: "hospital_icon", icon: true },
  { iconName: <BuildingIcon />, value: "building_icon", icon: true },
  { iconName: <FlashIcon />, value: "flash_icon", icon: true },
  { iconName: <HomeIcon />, value: "home_icon", icon: true },
  { iconName: <StarIcon />, value: "star_icon", icon: true },
  { iconName: <BriefCaseIcon />, value: "briefcase_icon", icon: true },
];

const logoIconColors = [
  {
    gradient: "linear-gradient(136deg, #00D55A 7.5%, #007257 93.77%)",
    value: "green",
  },
  {
    gradient: "linear-gradient(136deg, #4CA4EC 7.5%, #2C5FFE 93.77%)",
    value: "blue",
  },
  {
    gradient: "linear-gradient(136deg, #D33D3E 7.5%, #812526 93.77%)",
    value: "red",
  },
  {
    gradient: "linear-gradient(136deg, #9249F1 7.5%, #63338E 93.77%)",
    value: "violet",
  },
];

const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: "10px",
  // border: "1px solid #242121",
  "& .main__container": {
    borderRadius: "10px",
  },
  "& .overlay": {
    display: "none",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  "&:hover": {
    "#upload_new": {
      display: "none",
    },
    "& .overlay": {
      display: "flex",
    },
    "& svg": {
      "& path": {
        stroke: "#fff",
      },
    },
  },
}));

export default function General() {
  // var userInfo = localStorage.getItem("userInfo");

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);

  const cropperRef = useRef(null);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agent_id_vita");
  const cookieAgencyId = Cookies.get("agency_id_vita");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
    }
  }, [token, cookieAgencyId]);

  const [loading, setLoading] = useState(false);
  const [agencyDetailData, setAgencyDetailData] = useState({});
  const [name, setName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryContactNumber, setContactNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState(null); // state/region
  const [country, setCountry] = useState("United States of America");
  const [zipCode, setZipCode] = useState("");
  const [logoFilename, setLogoFilename] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showReferees, setShowReferees] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [changedField, setChangedField] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("avatar_icon");
  const [selectedIconColor, setSelectedIconColor] = useState("blue");
  const [logoType, setLogoType] = useState(null);
  const [logoSizeError, setLogoSizeError] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const [status, setStatus] = useState({ type: "", message: "" });
  const [permissionMessage, setPermissionMessage] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [selectedFileError, setSelectedFileError] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const updateAgency = useSelector((state) => state.updateAgency);
  const {
    loading: loadingAgency,
    error: errorAgency,
    updatedAgency,
  } = updateAgency;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  // useEffect(() => {
  //   dispatch(getAgencyDetailFunc());
  // }, []);

  const hiddenFileInputRef = useRef();

  const handleFileClick = () => {
    hiddenFileInputRef.current.click();
  };

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "settings_general_tab",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  // console.log("LOADING AGENCY", loadingAgency);
  // console.log("UPDATE AGENCY", updatedAgency);
  // console.log("ERRORRRRRR", errorAgency);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  // const formatContactNumber = (input) => {
  //   // Remove non-numeric characters from input
  //   const cleanedInput = input.replace(/\D/g, "");

  //   // Apply regex to format the contact number
  //   const formattedInput = cleanedInput.replace(
  //     /^(\d{1})(\d{3})(\d{3})(\d{4})$/,
  //     "+$1 ($2) $3-$4"
  //   );

  //   return formattedInput;
  // };

  const contactNumberHandler = (e) => {
    setChangedField(true);
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setContactNumber(formattedNumber);
  };

  const websiteHandler = (e) => {
    setChangedField(true);
    setWebsite(e.target.value);
  };

  const getAgencyData = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agency/get?agency_id=${cookieAgencyId}`,
        config
      );

      setAgencyDetailData(data?.agency);

      const {
        name,
        primary_email,
        primary_contact_number,
        address,
        website,
        city,
        country,
        region,
        zipcode,
        logo_filename,
        logo_url,
        show_referees,
        icon_name,
        icon_color,
        logo_type,
      } = data?.agency;

      setName(name ? name : "");
      setPrimaryEmail(primary_email ? primary_email : "");
      setContactNumber(primary_contact_number ? primary_contact_number : "");
      setAddress(address ? address : "");
      setCity(city ? city : "");
      setRegion(region ? region : null);
      setWebsite(website ? website : "");
      setCountry("United States of America");
      setZipCode(zipcode ? zipcode : "");
      setLogoFilename(logo_filename ? logo_filename : "");
      setSelectedIcon(icon_name ? icon_name : "avatar_icon");
      setSelectedIconColor(icon_color ? icon_color : "blue");
      setLogoType(logo_type ? logo_type : "icon");

      setLogoURL(logo_url ? logo_url : "");

      setShowReferees(show_referees ? show_referees : false);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeRefereeHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_lawfirm_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update law firm settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const referreStatus = e.target.checked;
      setShowReferees(referreStatus);

      const agencyData = {
        agency_id: cookieAgencyId,
        agency_settings: {
          show_referees: referreStatus,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  useEffect(() => {
    if (!loadingAgency && updatedAgency?.ok) {
      dispatch(getAllAgenciesListFunc());
      dispatch(getAgencyDetailFunc());
      setShowButtons(false);
    }
  }, [updatedAgency]);

  const emailChangeHandler = (e) => {
    setChangedField(true);

    const emailInputValue = e.target.value;
    setPrimaryEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    setEmailError(!emailIsValid);
  };

  const handleZipCodeChange = (e) => {
    setChangedField(true);

    // Only update if the input is a number or empty (to allow deletion)
    if (/^\d*$/g.test(e.target.value)) {
      setZipCode(e.target.value);
    }
  };

  const emailBlurHandler = () => {
    if (!primaryEmail) {
      setEmailError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!changedField) return;

    const access =
      agentUserRef.current?.agent?.permissions?.update_lawfirm_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update law firm settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      if (primaryEmail && !validateEmail(primaryEmail)) {
        return;
      }

      setStatus({
        type: "",
        message: "",
      });

      setLoading(true);

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const agencyData = {
          agency_id: cookieAgencyId,
          agency_settings: {
            name,
            primary_email: primaryEmail,
            primary_contact_number: primaryContactNumber,
            website,
            address,
            city,
            country,
            region: region,
            zipcode: zipCode,
            logo_filename: logoFilename,
          },
        };

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agency/update`,
          agencyData,
          config
        );

        if (!res?.data?.ok) {
          throw Error(res?.data?.message);
        }

        setStatus({
          type: "success",
          message: "Your changes have been saved successfully",
        });
        setLoading(false);
        handleAlertClick();
        getAgencyData();

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "lawfirm_details_updated",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } catch (error) {
        setStatus({
          type: "error",
          message:
            error.data && error.data.message
              ? error.data.message
              : error.message,
        });
        setLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (token) {
      getAgencyData();
    }
  }, [token, cookieAgencyId]);

  // RESET CHANGES HANDLER
  const resetChangesHandler = (e) => {
    e.preventDefault();

    if (!changedField) return;

    setChangedField(false);
    // if (agencyDetailData?.primary_email) {
    //   const emailIsValid = validateEmail(agencyDetailData?.primary_email);

    //   if (!emailIsValid) {
    //     setEmailError(true);
    //     return;
    //   }

    //   setEmailError(false);
    // }

    setName(agencyDetailData?.name ? agencyDetailData?.name : "");
    setPrimaryEmail(
      agencyDetailData?.primary_email ? agencyDetailData?.primary_email : ""
    );
    setContactNumber(
      agencyDetailData?.primary_contact_number
        ? agencyDetailData?.primary_contact_number
        : ""
    );
    setAddress(agencyDetailData?.address ? agencyDetailData?.address : "");
    setCity(agencyDetailData?.city ? agencyDetailData?.city : "");
    setRegion(agencyDetailData?.region ? agencyDetailData?.region : null);
    // setCountry(country ? country : "");
    setCountry("United States of America");
    setZipCode(agencyDetailData?.zipcode ? agencyDetailData?.zipcode : "");
  };

  const selectedIconHandler = (iconValue) => {
    setShowButtons(true);
    setSelectedIcon(iconValue);
  };

  const selectedIconColorHandler = (iconColor) => {
    setShowButtons(true);
    setSelectedIconColor(iconColor);
  };

  const deleteFileHandler = () => {
    if (!logoFilename) return;

    setLogoFilename("");
    setLogoURL("");

    const agencyData = {
      agency_id: cookieAgencyId,
      agency_settings: {
        logo_filename: "",
        logo_type: null,
      },
    };

    dispatch(updateAgencyFunc(agencyData));
  };

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const fileType = file?.type;

    if (file && file?.size > 5 * 1024 * 1024) {
      setLogoSizeError("File size cannot exceed more than 5MB.");
      setAlertOpen(true);
      setLogoURL("");
      setSelectedFileError("File size cannot exceed more than 2MB");
      return;
    }

    if (
      fileType !== "image/jpeg" &&
      fileType !== "image/png" &&
      fileType !== "image/jpg"
    ) {
      setLogoSizeError("Only png and jpg images are allowed!");
      setAlertOpen(true);
      return;
    }

    event.target.value = "";

    setOpenImageModal(true);
    setLogoImage(URL.createObjectURL(file));

    // const fileData = new FormData();
    // fileData.append("file", file);
    // fileData.append("file_type", fileType);
    // fileData.append("agency_id", agencyID);

    // try {
    //   setUploading(true);
    //   const config = {
    //     headers: {
    //       "Content-type": "multipart/form-data",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   };

    //   const res = await axios.post(
    //     `${process.env.REACT_APP_BASE_URL}/agent/upload-file`,
    //     fileData,
    //     config
    //   );

    //   // if (imageUrl && !res?.data?.ok) {
    //   //   setImageName(imageName);
    //   //   setImageUrl(imageUrl);
    //   // }

    //   // console.log("RESSSS", res);

    //   if (!res?.data?.ok) {
    //     setProfilePictureFilename("");
    //     setProfilePictureURL(
    //       "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
    //     );
    //     setUploading(false);
    //     return;
    //   }

    //   setProfilePictureFilename(res?.data?.filename);
    //   setProfilePictureURL(res?.data?.file_url);
    //   setUploading(false);
    //   setAlertOpen(true);

    //   const agentData = {
    //     agent_id: agentID,
    //     agent_profile: {
    //       profile_picture_filename: res?.data?.filename,
    //     },
    //   };

    //   dispatch(updateAgentProfile(agentData));
    // } catch (error) {
    //   console.log(error);
    //   // setSelectedFileError(
    //   //   error.response && error.response.data.message
    //   //     ? error.response.data.message
    //   //     : error.message
    //   // );
    //   setUploading(false);
    // } finally {
    //   setProfileSizeError(null);
    // }
  };

  const closeImageModal = () => {
    setOpenImageModal(false);
    setLogoImage("");
  };

  const onCrop = () => {
    const cropper = cropperRef?.current?.cropper;
  };

  const handleZoomIn = () => {
    const cropper = cropperRef.current.cropper;
    cropper.zoom(0.1); // You can adjust the zoom factor as needed
  };

  const handleZoomOut = () => {
    const cropper = cropperRef.current.cropper;
    cropper.zoom(-0.1); // Negative value zooms out, adjust the value as needed
  };

  const resetIconHandler = () => {
    setSelectedIcon(
      agencyDetail?.agency?.icon_name
        ? agencyDetail?.agency?.icon_name
        : "avatar_icon"
    );
    setSelectedIconColor(
      agencyDetail?.agency?.icon_color
        ? agencyDetail?.agency?.icon_color
        : "blue"
    );
    setShowButtons(false);
  };

  const saveIconHandler = () => {
    const agencyData = {
      agency_id: cookieAgencyId,
      agency_settings: {
        logo_type: "icon",
        icon_color: selectedIconColor,
        icon_name: selectedIcon,
      },
    };

    dispatch(updateAgencyFunc(agencyData));
    setAlertOpen(true);
  };

  const uploadApi = async (fileData) => {
    try {
      setUploading(true);
      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/agency/upload-file`,
        fileData,
        config
      );

      // if (imageUrl && !res?.data?.ok) {
      //   setImageName(imageName);
      //   setImageUrl(imageUrl);
      // }

      if (!res?.data?.ok) {
        setLogoFilename("");
        setLogoURL("");
        setUploading(false);
        return;
      }

      setLogoFilename(res?.data?.filename);
      setLogoURL(res?.data?.file_url);

      const agencyData = {
        agency_id: cookieAgencyId,
        agency_settings: {
          logo_filename: res?.data?.filename,
          logo_type: "custom",
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      setUploading(false);
      setOpenImageModal(false);
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "headshot_uploaded",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } catch (error) {
      console.log(error);
      // setSelectedFileError(
      //   error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message
      // );
      setUploading(false);
    } finally {
      setSelectedFileError(null);
    }
  };

  const saveImageHandler = async () => {
    if (
      typeof cropperRef?.current?.cropper?.getCroppedCanvas() === "undefined"
    ) {
      return;
    }
    cropperRef?.current?.cropper?.getCroppedCanvas()?.toBlob(async (blob) => {
      const croppedImageFile = new File([blob], "croppedImage.jpg", {
        type: "image/png",
      });
      // setCroppedImage(croppedImageFile);

      const fileData = new FormData();
      fileData.append("file", croppedImageFile);
      fileData.append("file_type", "image/png");
      fileData.append("agency_id", cookieAgencyId);

      uploadApi(fileData);
    }, "image/png");
  };

  return (
    <>
      {!loading && status?.type === "success" && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loading && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && errorAgency && (
        <AlertMessage
          message={errorAgency}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && updatedAgency?.ok && (
        <AlertMessage
          message="Your changes have been saved successfully"
          severity="success"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      {logoSizeError && (
        <AlertMessage
          message={logoSizeError}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      {openImageModal && (
        <DialogOverlay open={openImageModal} height="587px">
          <ProfileModal
            headerTitle="Crop Profile Picture"
            closeFormDialogHandler={closeImageModal}
            saveImageHandler={saveImageHandler}
            uploading={uploading}
          >
            <Box
              position="relative"
              height="100%"
              sx={{
                overflow: "hidden",
                ".cropper-crop-box, .cropper-view-box": {
                  // borderRadius: "50%",
                  ".cropper-modal": {
                    backgroundColor: "#000 !important",
                  },
                  // ".cropper-view-box": {
                  //   outline: "none !important",
                  // },
                  ".cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line":
                    {
                      display: "none !important",
                    },
                  ".cropper-face": {
                    backgroundColor: "inherit !important",
                  },
                },
              }}
            >
              {logoImage && (
                <Cropper
                  ref={cropperRef}
                  style={{
                    height: "100% !important",
                    width: "100%",
                  }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  preview=".img-preview"
                  src={logoImage}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  // onInitialized={(instance) => {
                  //   setCropper(instance);
                  // }}
                  guides={true}
                  crop={onCrop}
                />
              )}
              <Box
                display="flex"
                position="absolute"
                bottom="18.5px"
                left="23px"
                gap="4px"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  size="small"
                  disableRipple
                  onClick={handleZoomIn}
                >
                  <Icon sx={{ width: "100%", height: "100%" }}>
                    <ZoominIcon />
                  </Icon>
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  disableRipple
                  onClick={handleZoomOut}
                >
                  <Icon sx={{ width: "100%", height: "100%" }}>
                    <ZoomoutIcon />
                  </Icon>
                </Button>
              </Box>
            </Box>
          </ProfileModal>
        </DialogOverlay>
      )}

      <Grid container spacing={2} width="100%">
        <Grid
          item
          gap={3}
          xs={12}
          md={8}
          // md={12}
          //   className={classes.grid_column}
        >
          {/* AGENCY DETAILS */}
          <CardUI maxWidth="100%" dashboard="true" padding="32px">
            <Grid item>
              <Stack component="form" direction="column" gap="24px">
                <Typography
                  variant="boxHeading"
                  textTransform="uppercase"
                  color="secondary"
                >
                  Practice Details{" "}
                </Typography>

                {loading ? (
                  <GeneralSkeleton />
                ) : (
                  <>
                    <Box display="flex" gap="24px">
                      <Textfield
                        placeholder="Enter Name"
                        label="Practice Name"
                        variant="outlined"
                        name="firmName"
                        width="100%"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setChangedField(true);
                        }}
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                        // margin="dense"
                      />
                      <Textfield
                        placeholder="contact@example.com"
                        label="Email Address"
                        variant="outlined"
                        name="email"
                        width="100%"
                        value={primaryEmail}
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                        // margin="dense"
                        // error={isTouched && !!error}
                        errorContent={emailError && "Enter valid email"}
                        helperText={emailError && "Enter valid email"}
                      />
                    </Box>
                    <Box display="flex" gap="24px">
                      <Textfield
                        placeholder="+1 7776665555"
                        label="Contact Number"
                        variant="outlined"
                        name="contactNumber"
                        width="100%"
                        value={primaryContactNumber}
                        onChange={contactNumberHandler}
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                        // margin="dense"
                      />
                      <Textfield
                        placeholder="www.acme.com"
                        label="Website"
                        variant="outlined"
                        name="contactNumber"
                        width="100%"
                        value={website}
                        onChange={websiteHandler}
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                        // margin="dense"
                      />
                    </Box>
                    <Box>
                      <Textfield
                        placeholder="90210 Broadway Blvd"
                        label="Address"
                        variant="outlined"
                        name="address"
                        fullWidth
                        width="100%"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          setChangedField(true);
                        }}
                        // margin="dense"
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                      />
                    </Box>
                    <Box display="flex" gap="24px">
                      {/* <TextField
                        placeholder="United States of America"
                        margin="dense"
                        label="Country"
                        variant="outlined"
                        name="country"
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                        value="United States of America"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: "transparent",
                            border: "none",
                            color: "black",
                            fontSize: "1em",
                          },
                        }}
                        // onChange={(e) => setCountry(e.target.value)}
                      /> */}

                      <Textfield
                        placeholder="San Mateo"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        width="100%"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                          setChangedField(true);
                        }}
                        // margin="dense"
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                      />

                      <Autocomplete
                        options={states}
                        fullWidth
                        value={region}
                        onChange={(event, newValue) => {
                          setRegion(newValue);
                          setChangedField(true);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State/Region"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        sx={{ marginTop: -1 }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      gap="24px"
                      width="48%"
                      alignItems="center"
                    >
                      <Textfield
                        placeholder="91000"
                        label="Zip Code"
                        variant="outlined"
                        name="zipcode"
                        fullWidth
                        width="100%"
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        // margin="dense"
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap="16px"
                    >
                      <Button
                        title="Reset Changes"
                        color="primary"
                        onClick={resetChangesHandler}
                        name="reset-changes"
                        fontSize="14px"
                      />

                      <Button
                        title="Save Changes"
                        disabled={!name || !changedField}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        // height="40px"
                        // width="140px"
                      />
                    </Box>
                  </>
                )}
              </Stack>
            </Grid>
          </CardUI>
        </Grid>

        {/* PRACTICE LOGO  */}
        <Grid item xs={12} md={4}>
          <CardUI maxWidth="353px" dashboard="true" padding="32px">
            <Stack gap="24px">
              <Stack display="flex" gap="8px">
                <Typography
                  variant="caption"
                  color="secondary"
                  fontWeight={500}
                >
                  Logo
                </Typography>{" "}
                {loading ? (
                  <RectangleSkeletons height="203px" totalRows={1} />
                ) : (
                  <StyledStack>
                    <Stack alignItems="center">
                      <Box
                        position="relative"
                        border="1px solid #C3C3C3"
                        height="203px"
                        width="100%"
                        borderRadius="8px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {logoFilename ? (
                          <Box
                            width="185px"
                            height="185px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="8px"
                            overflow="hidden"
                          >
                            <img
                              width="100%"
                              height="100%"
                              src={logoURL}
                              alt="practice logo"
                            />
                          </Box>
                        ) : (
                          <Stack
                            component="div"
                            id="upload_new"
                            alignItems="center"
                            justifyContent="center"
                            gap="4px"
                            height="100%"
                          >
                            <AddLogo />

                            <Typography variant="caption" color="secondary">
                              Upload New
                            </Typography>
                          </Stack>
                        )}

                        <Stack
                          className="overlay"
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Stack
                            alignItems="center"
                            width="50%"
                            gap="4px"
                            sx={{ cursor: "pointer" }}
                            onClick={handleFileClick}
                          >
                            <AddLogoIcon />

                            <Typography variant="caption" color="primary.light">
                              Upload New
                            </Typography>
                            <input
                              accept="image/png, image/jpg, image/jpeg"
                              type="file"
                              ref={hiddenFileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                              id="document-input"
                            />
                          </Stack>
                          {logoURL && (
                            <>
                              {" "}
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{
                                  backgroundColor: "primary.light",
                                  height: "50%",
                                  margin: "auto 0 !important",
                                }}
                              />
                              <Stack
                                alignItems="center"
                                width="50%"
                                gap="4px"
                                sx={{
                                  cursor: "pointer",
                                  margin: "0 !important",
                                }}
                                onClick={deleteFileHandler}
                              >
                                <DeleteLogoIcon />

                                <Typography
                                  variant="caption"
                                  color="primary.light"
                                >
                                  Delete
                                </Typography>
                              </Stack>{" "}
                            </>
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                  </StyledStack>
                )}
                <Typography variant="caption" color="rgba(0, 0, 0, 0.30)">
                  jpeg, png max 5MB
                </Typography>
              </Stack>

              <Divider>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                  or
                </Typography>
              </Divider>

              <Stack
                gap="12px"
                sx={{
                  opacity: logoURL || loading ? "0.5" : "1",
                  pointerEvents: logoURL || loading ? "none" : "auto",
                }}
              >
                <Typography variant="caption" color="secondary">
                  Make an avatar
                </Typography>
                <Stack gap="8px">
                  <Typography
                    padding="0 8px"
                    variant="caption"
                    color="rgba(0, 0, 0, 0.56)"
                  >
                    Choose Icon{" "}
                  </Typography>
                  <Box
                    padding="0 8px"
                    display="flex"
                    alignItems="center"
                    gap="4px"
                  >
                    {logoIcons?.map((item, index) => (
                      <Box
                        width="30px"
                        height="30px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        key={index}
                        borderRadius="4px"
                        padding="2px"
                        border={
                          selectedIcon === item?.value
                            ? "1.5px solid #0C80F3"
                            : ""
                        }
                        onClick={() => selectedIconHandler(item?.value)}
                      >
                        {item?.icon ? (
                          <IconButton
                            sx={{
                              "&:hover": {
                                borderRadius: "8px",
                              },
                            }}
                          >
                            {item?.iconName}
                          </IconButton>
                        ) : (
                          <IconButton
                            sx={{
                              width: "30px",
                              height: "30px",
                              "&:hover": {
                                borderRadius: "8px",
                              },
                            }}
                          >
                            <Avatar
                              variant="rounded"
                              {...stringAvatar(name)}
                              sx={{
                                width: "30px",
                                height: "30px",
                                background: "none",
                                color: "#0D0D0D",
                                fontSize: "16px",
                              }}
                            />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Stack>
                <Stack gap="8px">
                  <Typography
                    padding="0 8px"
                    variant="caption"
                    color="rgba(0, 0, 0, 0.56)"
                  >
                    Choose Color{" "}
                  </Typography>
                  <Box display="flex" alignItems="center" gap="8px">
                    {logoIconColors?.map((item, index) => (
                      <Box
                        key={index}
                        padding="2px"
                        sx={{ cursor: "pointer" }}
                        onClick={() => selectedIconColorHandler(item?.value)}
                        borderRadius="6px"
                        border={
                          selectedIconColor === item?.value
                            ? "1.5px solid #0C80F3"
                            : ""
                        }
                      >
                        <Box
                          width="30px"
                          height="30px"
                          borderRadius="5px"
                          sx={{ background: item?.gradient }}
                        ></Box>
                      </Box>
                    ))}
                  </Box>
                </Stack>
              </Stack>

              {showButtons && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="12px"
                >
                  <Button variant="text" onClick={resetIconHandler}>
                    Reset
                  </Button>
                  <LoadingButton
                    loading={loadingAgency}
                    variant="contained"
                    onClick={saveIconHandler}
                  >
                    Save
                  </LoadingButton>
                </Box>
              )}
            </Stack>
          </CardUI>
        </Grid>
      </Grid>
    </>
  );
}
