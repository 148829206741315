import {
  ADD_NEW_CASE_FAIL,
  ADD_NEW_CASE_REQUEST,
  ADD_NEW_CASE_SUCCESS,
  ADD_NOTE_TO_CASE_FAIL,
  ADD_NOTE_TO_CASE_REQUEST,
  ADD_NOTE_TO_CASE_SUCCESS,
  ADD_QUESTION_TO_CASE_FAIL,
  ADD_QUESTION_TO_CASE_REQUEST,
  ADD_QUESTION_TO_CASE_SUCCESS,
  DELETE_CASE_FAIL,
  DELETE_CASE_REQUEST,
  DELETE_CASE_SUCCESS,
  GET_AGENCY_PATIENTS_FAIL,
  GET_AGENCY_PATIENTS_REQUEST,
  GET_AGENCY_PATIENTS_SUCCESS,
  GET_ALL_CASES_FAIL,
  GET_ALL_CASES_REQUEST,
  GET_ALL_CASES_SUCCESS,
  GET_A_CASE_BY_CID_FAIL,
  GET_A_CASE_BY_CID_REQUEST,
  GET_A_CASE_BY_CID_SUCCESS,
  GET_CASE_BY_AGENT_FAIL,
  GET_CASE_BY_AGENT_REQUEST,
  GET_CASE_BY_AGENT_SUCCESS,
  GET_CASE_CLIENT_DOCUMENTS_FAIL,
  GET_CASE_CLIENT_DOCUMENTS_REQUEST,
  GET_CASE_CLIENT_DOCUMENTS_SUCCESS,
  GET_CHART_PAINLOGS_FAIL,
  GET_CHART_PAINLOGS_REQUEST,
  GET_CHART_PAINLOGS_SUCCESS,
  GET_CLIENT_PAINLOGS_FAIL,
  GET_CLIENT_PAINLOGS_REQUEST,
  GET_CLIENT_PAINLOGS_SUCCESS,
  GET_DASHBOARD_ANALYTICS_FAIL,
  GET_DASHBOARD_ANALYTICS_REQUEST,
  GET_DASHBOARD_ANALYTICS_SUCCESS,
  GET_HIGHRISK_CASES_FAIL,
  GET_HIGHRISK_CASES_REQUEST,
  GET_HIGHRISK_CASES_SUCCESS,
  GET_PIE_CHART_STATS_FAIL,
  GET_PIE_CHART_STATS_REQUEST,
  GET_PIE_CHART_STATS_SUCCESS,
  GET_UNPAGINATED_CASES_FAIL,
  GET_UNPAGINATED_CASES_REQUEST,
  GET_UNPAGINATED_CASES_SUCCESS,
  GET_UNREAD_CHAT_MESSAGES_FAIL,
  GET_UNREAD_CHAT_MESSAGES_REQUEST,
  GET_UNREAD_CHAT_MESSAGES_SUCCESS,
  RESET_ADD_NOTE_TO_CASE,
  RESET_ADD_QUESTION_TO_CASE,
  RESET_ALL_CASES,
  RESET_A_CASE_BY_CID,
  RESET_CASE_CLIENTS_DOCUMENTS,
  RESET_DELETE_CASE,
  RESET_GET_AGENCY_PATIENTS,
  RESET_PIE_CHART_STATS,
  RESET_UNPAGINATED_CASES,
  UPDATE_CASE_FAIL,
  UPDATE_CASE_REQUEST,
  UPDATE_CASE_RESET,
  UPDATE_CASE_SUCCESS,
} from "../../assets/constants/caseConstants";

// GET ALL CASES REDUCER
export const getAllCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CASES_REQUEST:
      return { loading: true };
    case GET_ALL_CASES_SUCCESS:
      return { loading: false, cases: action.payload };
    case GET_ALL_CASES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_CASES:
      return { loading: true, cases: [] };
    default:
      return state;
  }
};

// GET ALL PATIENTS BY AGENCY REDUCER
export const getAllPatientsByAgencyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_PATIENTS_REQUEST:
      return { loading: true, allAgencyPatients: [], error: null };
    case GET_AGENCY_PATIENTS_SUCCESS:
      return { loading: false, allAgencyPatients: action.payload, error: null };
    case GET_AGENCY_PATIENTS_FAIL:
      return { loading: false, error: action.payload, allAgencyPatients: [] };
    case RESET_GET_AGENCY_PATIENTS:
      return {};
    default:
      return state;
  }
};

// GET ALL UNPAGINATED CASES REDUCER
export const getUnpaginatedCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNPAGINATED_CASES_REQUEST:
      return { loading: true, allUnpaginatedCases: [] };
    case GET_UNPAGINATED_CASES_SUCCESS:
      return { loading: false, allUnpaginatedCases: action.payload };
    case GET_UNPAGINATED_CASES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UNPAGINATED_CASES:
      return { loading: false, allUnpaginatedCases: [] };
    default:
      return state;
  }
};

// GET A CASE BY CID REDUCER
export const getCaseByCaseIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_A_CASE_BY_CID_REQUEST:
      return { loading: true };
    case GET_A_CASE_BY_CID_SUCCESS:
      return { loading: false, caseDetail: action.payload };
    case GET_A_CASE_BY_CID_FAIL:
      return { loading: false, error: action.payload };
    case RESET_A_CASE_BY_CID:
      return {};
    default:
      return state;
  }
};

// GET A CASE BY AGENT REDUCER
export const getCaseByAgentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CASE_BY_AGENT_REQUEST:
      return { loading: true };
    case GET_CASE_BY_AGENT_SUCCESS:
      return { loading: false, caseInfoByAgent: action.payload };
    case GET_CASE_BY_AGENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET ALL CASES REDUCER
export const updateCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CASE_REQUEST:
      return { loading: true };
    case UPDATE_CASE_SUCCESS:
      return { loading: false, updatedCase: action.payload };
    case UPDATE_CASE_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_CASE_RESET:
      return {};
    default:
      return state;
  }
};

// GET CLIENT PAINLOGS REDUCER
export const getClientPainlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_PAINLOGS_REQUEST:
      return { loading: true };
    case GET_CLIENT_PAINLOGS_SUCCESS:
      return { loading: false, painlogs: action.payload };
    case GET_CLIENT_PAINLOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET CHART PAINLOGS REDUCER
export const getChartPainlogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHART_PAINLOGS_REQUEST:
      return { loading: true };
    case GET_CHART_PAINLOGS_SUCCESS:
      return { loading: false, chartPainLogs: action.payload };
    case GET_CHART_PAINLOGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ADD/POST NEW CASE REDUCER
export const addNewCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_CASE_REQUEST:
      return { loading: true };
    case ADD_NEW_CASE_SUCCESS:
      return { loading: false, newcase: action.payload };
    case ADD_NEW_CASE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE CASE LISTING REDUCER
export const deleteCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CASE_REQUEST:
      return { loading: true };
    case DELETE_CASE_SUCCESS:
      return { loading: false, success: true };
    case DELETE_CASE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_CASE:
      return { loading: false, success: false };
    default:
      return state;
  }
};

// GET CASE AND CLIENTS LEVEL DOCUMENTS REDUCER
export const getCaseClientDocumentsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CASE_CLIENT_DOCUMENTS_REQUEST:
      return { loading: true };
    case GET_CASE_CLIENT_DOCUMENTS_SUCCESS:
      return { loading: false, caseClientDocs: action.payload };
    case GET_CASE_CLIENT_DOCUMENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CASE_CLIENTS_DOCUMENTS:
      return {};
    default:
      return state;
  }
};

// GET DASHBOARD ANALYTICS REDUCER
export const getDashboardAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ANALYTICS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DASHBOARD_ANALYTICS_SUCCESS:
      return { ...state, loading: false, dashboardAnalytic: action.payload };
    case GET_DASHBOARD_ANALYTICS_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET HIGH RISK CASES REDUCER
export const getHighRiskCasesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HIGHRISK_CASES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_HIGHRISK_CASES_SUCCESS:
      return {
        ...state,
        loading: false,
        highRiskCases: action.payload,
        error: null,
      };
    case GET_HIGHRISK_CASES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET UNREAD CHAT MESSAGES REDUCER
export const getUnreadChatMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNREAD_CHAT_MESSAGES_REQUEST:
      return { loading: true, error: null };
    case GET_UNREAD_CHAT_MESSAGES_SUCCESS:
      return {
        loading: false,
        unreadChatMessages: action.payload,
        error: null,
      };
    case GET_UNREAD_CHAT_MESSAGES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET PIE CHART STATS REDUCER
export const getPieChartStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PIE_CHART_STATS_REQUEST:
      return { loading: true, error: null };
    case GET_PIE_CHART_STATS_SUCCESS:
      return {
        loading: false,
        pieChartStats: action.payload,
        error: null,
      };
    case GET_PIE_CHART_STATS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_PIE_CHART_STATS:
      return { loading: false, error: null, pieChartStats: {} };
    default:
      return state;
  }
};

// ADD QUESTION TO CASE REDUCER
export const addQuestionToCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_QUESTION_TO_CASE_REQUEST:
      return { loading: true };
    case ADD_QUESTION_TO_CASE_SUCCESS:
      return { loading: false, addedQuestion: action.payload };
    case ADD_QUESTION_TO_CASE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_QUESTION_TO_CASE:
      return {};
    default:
      return state;
  }
};

// ADDNOTE TO CASE REDUCER
export const addNoteToCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NOTE_TO_CASE_REQUEST:
      return { loading: true };
    case ADD_NOTE_TO_CASE_SUCCESS:
      return { loading: false, addedNote: action.payload };
    case ADD_NOTE_TO_CASE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_NOTE_TO_CASE:
      return {};
    default:
      return state;
  }
};
