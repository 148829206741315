import React from "react";

const ChatSeenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.66699 8.60781L4.76223 11.0078L10.0003 5.00781"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.333 5.04883L7.61856 11.0488L7.33301 10.6738"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatSeenIcon;
