import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { dateFormat } from "../../utils/helpers";
import styled from "@emotion/styled";

const StyledList = styled(Box)(({}) => ({
  lineHeight: "26px",
  fontSize: "14px",
}));

const ConsentScreenContent = ({
  tosConsentChecked,
  setTosContentChecked,
  initSetupChecked,
  setInitSetupChecked,
  initSetup2Checked,
  setInitSetup2Checked,
  patientDetail,
  cptCodeNotes,
  setCptCodeNotes,
}) => {
  return (
    <Stack gap="24px">
      <Stack gap="8px" padding="8px" borderRadius="8px" bgcolor="#F4F6F8">
        <Typography variant="body1" color="#212121" fontWeight={500}>
          Patient Details
        </Typography>
        <Box display="flex" width="100%">
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Name
            </Typography>
            <Typography variant="body1">
              {patientDetail?.patient?.name
                ? patientDetail?.patient?.name
                : "-"}
            </Typography>
          </Stack>
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              DOB
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.dob
                ? dateFormat(patientDetail?.patient?.dob)
                : "-"}
            </Typography>
          </Stack>
        </Box>
        <Box display="flex" width="100%">
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Contact Number
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.contact_number
                ? patientDetail?.patient?.contact_number
                : "-"}
            </Typography>
          </Stack>
          <Stack gap="4px" width="50%">
            <Typography color="secondary" variant="body2">
              Email
            </Typography>
            <Typography variant="body1">
              {" "}
              {patientDetail?.patient?.email
                ? patientDetail?.patient?.email
                : "-"}
            </Typography>
          </Stack>
        </Box>
        {patientDetail?.patient?.tracking_consent_given && (
          <Box display="flex" width="100%">
            <Stack gap="4px" width="50%">
              <Typography color="secondary" variant="body2">
                Consent obtained on
              </Typography>
              <Typography variant="body1">
                {" "}
                {patientDetail?.patient?.consent_taken_on
                  ? dateFormat(patientDetail?.patient?.consent_taken_on)
                  : "-"}
              </Typography>
            </Stack>
            <Stack gap="4px" width="50%">
              <Typography color="secondary" variant="body2">
                Consent obtained by
              </Typography>
              <Typography variant="body1">
                {" "}
                {patientDetail?.patient?.consent_taken_by_name
                  ? patientDetail?.patient?.consent_taken_by_name
                  : "-"}
              </Typography>
            </Stack>
          </Box>
        )}
      </Stack>
      <Stack gap="16px">
        {!patientDetail?.patient?.tracking_consent_given && (
          <Stack>
            <FormControlLabel
              sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
              control={
                <Checkbox
                  sx={{ padding: "1px 9px" }}
                  checked={tosConsentChecked}
                  onChange={(e) => setTosContentChecked(e.target.checked)}
                />
              }
              label={
                <Typography>
                  The patient has read and agrees to the{" "}
                  <Box
                    component="a"
                    color="#0C80F3"
                    href="https://vitatrax.co/terms-of-service/"
                    target="_blank"
                  >
                    Terms of Service.
                  </Box>
                </Typography>
              }
            />
            <Stack marginLeft="32px" gap="2px">
              <Typography variant="body2" color="#13132B">
                By proceeding, the patient acknowledges and agrees to the
                following:
              </Typography>
              <Stack
                component="ul"
                paddingLeft="24px"
                gap="4px"
                color="rgba(0, 0, 0, 0.60)"
              >
                <StyledList component="li">
                  The patient agrees to participate in Remote Therapeutic
                  Monitoring (RTM).
                </StyledList>
                <StyledList component="li">
                  The patient understands that there may be a copay associated
                  with RTM services.
                </StyledList>
                <StyledList component="li">
                  The patient understands that RTM can be canceled at any time.
                </StyledList>
                <StyledList component="li">
                  Information related to the patient's care may be shared with
                  other healthcare providers.
                </StyledList>
                <StyledList component="li">
                  Only one practitioner will be able to bill for RTM services.
                </StyledList>
              </Stack>
            </Stack>
          </Stack>
        )}

        <FormControlLabel
          sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
          control={
            <Checkbox
              sx={{ padding: "1px 9px" }}
              checked={initSetupChecked}
              onChange={(e) => setInitSetupChecked(e.target.checked)}
            />
          }
          label="The initial setup of the equipment has been completed"
        />
        <FormControlLabel
          sx={{ marginRight: "0", flex: "1", alignItems: "flex-start" }}
          control={
            <Checkbox
              sx={{ padding: "1px 9px" }}
              checked={initSetup2Checked}
              onChange={(e) => setInitSetup2Checked(e.target.checked)}
            />
          }
          label="The patient has been educated on how to use the equipment"
        />
      </Stack>
      <TextField
        id="patient-interaction-notes"
        placeholder="Patient Interaction Notes"
        multiline
        rows={7}
        value={cptCodeNotes}
        onChange={(e) => setCptCodeNotes(e.target.value)}
      />
    </Stack>
  );
};

export default ConsentScreenContent;
